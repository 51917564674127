.returnToDashboard {
    display: flex;
    align-items: center;
    margin: 12px;
    width: fit-content;
    color: var(--people-sol-dark-grey, #555);
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    text-transform: capitalize;
    padding: 4px 8px;
}

.returnLabelHolder {
    padding-left: 10px;
    font-weight: 500;
}

.returnToDashboard:hover {
    background-color: var(--global-hover);
    transition: 0.6s;
}

.submitDeclareContainer {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.taxRegimeContainer {
    display: flex;
    /* border: 1px solid black; */
    min-height: 10rem;
    align-items: center;
    justify-content: center;

}

.taxRegimeHolder {
    display: flex;
    /* margin-right: 40px; */
    align-items: center;
    border: 1px solid white;
    border-radius: 5px;
    padding: 8px;
    height: 6rem;
    /* width: 35%; */
    box-shadow: 0px 1.89445px 10px rgba(0, 0, 0, 0.04);
}

.regimeContent {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
}

.activeBullet {
    background: grey;
    border-radius: 100%;
    height: 100%;
    width: 100%;
}

.taxBulletClass {
    margin-left: 5px;
    border: 0px;
    padding: 2px;
    height: 15px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.20);
}

.particularDetailsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 25px;
}

.particularQuestionHolder {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    background: #EFF9FA;
    border-radius: 6px;
}

.particularAnswer {
    margin-left: auto;
    display: flex;
    margin-right: 12px;
}

.particularAllHolder {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 100%; */
}

.extendedParticularDetails {
    width: calc(100% - 50px);
    /* height: 20rem; */
    padding-top: 14px;
}

.extendedHeaderContainer {
    display: flex;
    justify-content: space-between;
    height: 10%;
}

.extendedHeading {
    font-weight: 500;
    font-size: 16px;
}

.extendedFormContainer {
    display: flex;
    flex-flow: wrap;
    height: 90%;
    padding-top: 10px;
    contain: content;
    overflow: auto;
}

.extendedInputTag {
    border: 1px solid #E6E6E6;
    padding-left: 9px;
    border-radius: 5px;
    /* height: 50px; */
}

.extendedInputContainer {
    display: flex;
}

.extendedFormLabel {
    margin: 6px 0;
    font-weight: 400;
}

.extendedFormComp {
    display: flex;
    flex-direction: column;
    width: 27rem;
    margin-right: 20px;
}

.investmentListContainer {
    width: 100%;
    padding-top: 13px;
}

.investmentHeading {
    width: 100%;
    height: 55px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    background: #EFF9FA;
    border-radius: 5px;
}

.investmentAllContainer {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.maxLimitText {
    font-size: 11px;
    font-weight: 500;
    padding-left: 17px;
}

.otherSourceContainer {
    display: flex;
    width: 100%;
    padding: 10px 20px;
    align-items: center;
}

.computeTaxContainer {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px;
    margin-top: 15px;
    background: #EFF9FA;
    border-radius: 8px;
    height: 5rem;
}

.computeButton {
    margin-left: auto;
    border: 1px solid var(--primary);
    border-radius: 5px;
    padding: 6px;
    font-size: 11px;
    font-weight: 500;
    color: var(--primary);
}

.particularDetailsViewer {
    margin-top: 10px;
    align-self: center;
    padding-bottom: 20px;
    width: 97%;
}

.detailsHeadingViewer {
    height: 30px;
    align-items: center;
}

.headTAx {
    display: flex;
    align-items: center;
    padding: 0 2px;
}

.totalError {
    display: flex;
    padding-left: 14px;
    align-items: center;
    justify-content: space-between;
}

.taxHeading {
    top: 0;
    padding: 14px 12px;
    position: sticky;
    z-index: 5;
    padding: 12px 24px 12px 24px;
}

.detailsBodyViewer {
    padding: 10px;
    display: flex;
    width: 100%;
    align-items: center;
}

.addInvestmentHolder {
    width: 100%;
    padding: 0 17px;
    margin-top: 12px;
    height: 3rem;
}

.editIconHolder {
    position: absolute;
    top: 0px;
    right: 0;
    padding: 24px;
}

.taxRegimeTextDisplay {
    background: #FFF4E5;
    border-radius: 6px;
    display: flex;
    align-items: center;
}

.taxRegimeBoxText {
    color: var(--secondary);
    font-size: 10px;
    padding: 5px;
    font-weight: 500;
}

.removeRepeatedIconClass {
    height: 80%;
    width: 5%;
    display: flex;
    justify-content: flex-end;
    padding-right: 2px;
}

.taxHeadingRight {
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
}

.taxDashboardBox {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
}

.taxRepeatedDD {
    width: 50%;
    border: 1px solid #E6E6E6;
    /* height: 50px; */
    display: flex;
    align-items: center;
    border-radius: 5px;
}

.valueDisplayer {
    border: 1px solid rgb(230, 230, 230);
    padding: 9px;
    border-radius: 5px;
    color: black;
    width: 100%;
}

.noActionsTextContainer {
    margin: 11px;
    background: rgb(253, 210, 210);
    padding: 8px;
    border-radius: 5px;
    color: red;
    font-weight: 500;
}