.pageChangerIcon {
  margin-left: 12px;
}

.pageChangerIconNext {
  rotate: 180deg;
}

.pageChangerIconActive>svg>path {
  stroke: var(--primary);
}

.noDataFound {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 51px);
}

.fullTableContainer {
  height: calc(100% - 48px);
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  flex-grow: 1;
}

.filterSearchContainer {
  height: 0;
  position: absolute;
  background-color: #faf9f8;
  right: 0;
  z-index: 9;
  box-shadow: -5px 6px 15px rgb(0 0 0 / 5%);
  top: 0px;
  border-radius: 6px;
  border: 1px solid transparent;
  transition: 0.5s;
  overflow: hidden;
  width: 0;
  display: flex;
  flex-direction: column;
}

.filterSearchContainerActive {
  height: 90%;
  border: 1px solid #1296b038;
  overflow: auto;
  overflow-x: hidden;
  width: 40%;
}

.filterSearchContainer>h5 {
  margin: 12px;
  font-size: 13px;
}

.filterContainer {
  padding: 6px;
  background-color: #faf9f8;
}

.filterContainer>p {
  margin-left: 12px;
  font-size: 12px;
}

.filterSearchFooterContainer {
  background-color: white;
  width: 100%;
  bottom: 0;
  border-top: 1px solid var(--grey);
  justify-content: flex-end;
  display: flex;
}

.secondaryButton {
  border-style: solid;
  /* border-color: var(--primary); */
  /* border-width: 1px !important; */
  background-color: white;
  color: var(--primary);
}

.primaryButton {
  background-color: var(--primary);
  color: white;
  border-style: solid;
  /* border-color: var(--primary); */
  /* border-width: 1px !important; */
  box-shadow: 0px 3.52262px 7.04523px rgb(18 150 176 / 20%),
    inset 0px 3.52262px 8.80654px rgb(0 0 0 / 15%);
}

.backButton {
  background-color: white;
  color: var(--primary);
  border: 1px solid var(--primary) !important;
}

.button {
  margin: 12px;
  padding: 6px 12px;
  outline: 0;
  border: 0;
  border-radius: 4px;
  font-size: 15px;
  text-align: center;
  min-width: 100px;
  font-family: "Poppins";
}

.button:hover {
  cursor: pointer;
}

.filterListContainer {
  height: 100%;
  overflow: auto;
}

.search-box {
  height: 40px;
  border-radius: 40px;
  margin-left: auto;
}

.search-box:hover>.search-text {
  width: 240px;
  padding: 0 6px;
}

.search-box:hover>.search-btn {
  background: var(--primary-hover);
  color: black;
}

.search-btn {
  color: #e84118;
  float: right;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
  cursor: pointer;
  text-decoration: none;
}

.search-text {
  border: none;
  background: none;
  outline: none;
  float: left;
  padding: 0;
  color: var(--primary);
  transition: 0.4s;
  line-height: 40px;
  width: 0px;
  border-bottom: 1px solid var(--primary);
}

.search-text:not(:placeholder-shown) {
  width: 240px;
}

.importContainerbody {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.importContainerActive {
  height: 230px;
  border: 1px solid #1296b038;
  overflow: auto;
  width: 40%;
}

.noTab {
  height: 100%;
}

.centerContent {
  justify-content: center;
  align-items: center;
}