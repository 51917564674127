.moreActivityContainer {
  position: relative;
  z-index: 10;
  height:35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.moreActivityContainer:hover {
  background: var(--primary-hover);
  border-radius: 100px;
}



.deleteMsgConfirmAlert
  .react-confirm-alert-button-group
  button:nth-child(1) {
    background: none;
    color: red;
    font-size: 1rem;
    font-weight: 600;
    margin-right: 10px;
    padding: 8px 35px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.deleteMsgConfirmAlert
  .react-confirm-alert-button-group
  button:nth-child(2) {
    font-size: 16px;
    font-weight: 400;
    padding: 8px 10px;
    color: #fff;
    background-color: #1296b0;
}
.deleteMsgConfirmAlert
  .react-confirm-alert-button-group
  button:nth-child(3) {
    font-size: 16px;
    font-weight: 400;
    padding: 5px 5px;
    color: #fff;
    background-color: #1296b0;
}

.muteNotifContainer {
  background-color: #fff !important;
  padding: 30px;
  width: 500px;
  border-radius: 10px;
}
.muteNotifContainer h4 {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 5px;
  color: #555;
}
.muteNotifContainer p {
  font-family: Poppins;
  font-size: 12px;
  padding-bottom: 10px;
  color: #A1A1A1;
}
#group1{
  display: flex;
  align-items: start;
  flex-direction: column;
}
#group1 input{
  margin-bottom: 5px;
  cursor: pointer;
}
#group1 span{
  color: #555;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-left: 10px;
}
.muteNotifBtnContainer{
  float: right;
}
.muteNotifBtnContainer button:nth-child(1){
  border: none;
  padding: 15px 20px;
  margin-right: 10px;
  color: #F71D1D;
  text-align: center;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 8.495px; 
  border-radius: 3px;
  background: var(--white, #FFF);
  box-shadow: 0px 4px 8px 0px rgba(207, 207, 207, 0.40), 0px 1px 10px 0px rgba(0, 0, 0, 0.05) inset;
}
.muteNotifBtnContainer button:nth-child(2){
  border: none;
  padding: 15px 25px;
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 9px; 
  border-radius: 3px;
  background: var(--people-s-ol-primery, #1296B0);
  box-shadow: 0px 4px 8px 0px rgba(18, 150, 176, 0.20), 0px 4px 10px 0px rgba(0, 0, 0, 0.15) inset;
}
input[type="radio"] {
  height: 1.2rem;
  width: 1.2rem;
  margin-right: 0.5rem;
}
#hours {
  box-shadow: 0 0 0 1px var(--color);
  border-width: 0.2rem;
}

#hours:hover {
  border-width: 0;
}

#hours:checked {
  box-shadow: 0 0 0 1px var(--checked-color);
  background-color: #1296B0;
  /* border-width: 0.2rem; */
}