.flex-dashboardApprovalDetail{
    display: flex;
    margin: 1rem;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgb(0 0 0 / 10%);
    border-radius: 10px;
    height: 90px;
    width: 60%;
    background: #FFFFFF;
    background-image: url(../../../Assets/ApprovalBackground.svg);
    background-repeat: no-repeat ;
    background-position: right;
    background-size: contain;
}
.dashboardApprovalPercent{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 39.9623px;
    line-height: 55px;
    margin: 5px 10px;
    /* or 157% */
    
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;
    
    /* PeopleSOl Primery */
    
    color: #1296B0;
}
.dashboardApprovalRedPercent{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 39.9623px;
    line-height: 55px;
    margin: 5px 10px;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;

    /* Notification_1 */

    color: #F71D1D;

}
.dashboardApprovalAmount{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10.7323px;
    line-height: 20px;
    
    /* or 183% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;

    /* PeopleSol */

    color: #BFBFBF;
}

.ApprovalReason{
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    font-weight: 500;
    width: 40%;
}
/* .TotalApproval{
    padding: 0px 5px
} */