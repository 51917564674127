.addProjectButton{
    margin: 16px 16px;
    background: linear-gradient(180deg, #1296B0 0%, #117F95 100%) !important;
}
.hrmsHeaderTab{
    background-color: #eafdff;
    /* opacity: 0.3; */
    display: flex;
    align-items: center;
}
.uploadHolder{
    margin-right: 22px;
}
.downloadHolder{
    margin-right: 11px;
}
.filterHolder{
    margin-right: 11px;
}
.headerTabSearch{
    background: #eafdff;
    border: 0px;
}
.headerTabContainer > svg{
    position: absolute;
    right: 6%;
}
.headerTabContainer{
    width: 20% !important;
    margin-right: 4px;
}
