.titleColor {
    color: var(--primary);
}

.summaryBox1 {
    border: 1.5px solid var(--pms-pastel, #FFF2F0);
    display: flex;
    align-items: center;
    /* height: 60px; */
    height: 70px;
    border-radius: 8px;
    flex: 1;
}

.summaryBox2 {
    width: 44%;
    border: 1.5px solid var(--ld-pastel, #D4F2F1);
    display: flex;
    align-items: center;
    /* height: 60px; */
    height: 70px;
    border-radius: 8px;
    margin-left: 18px;
}

.categoryBox {
    width: 21rem;
    border: 1.5px solid var(--ld-pastel, #D4F2F1);
}

.progressBox {
    width: 46rem;
    border: 1.5px solid var(--tax-pastel, #F0F0FF);
}

.learningBox {
    height: 5rem;
    box-sizing: border-box;
    background: white;
    border-radius: 8.45708px;
    /* justify-content: space-around; */
    margin: 0.5rem;
    /* flex-grow: 1; */
    width: 335px !important;
    justify-content: space-around;
}

.mylearningdetailssection {
    display: flex;
    flex-direction: column;
    /* width: 48%; */

    width: 57%;
    min-width: 560px;
    margin-right: 25px;
}


.myteamdetailssection {
    display: flex;
    flex-direction: column;
    width: 57%;
    min-width: 575px;
    margin-right: 25px;
}

.mylearningdetailssubsection1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.mylearningdetailssubsection2 {
    width: 100%;
    display: flex;
    margin-top: 10px;

}

.homeGreetingContainerld {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    margin-left: 29px;
    flex-grow: 1;
}

.learningProgressBox {
    /* width: 100%; */
    display: flex;
    align-items: center;
    flex: 1;
    border-radius: 8px;
    border: 1.5px solid var(--tax---Pastel, #F0F0FF);
    /* height: 60px; */
    height: 70px;

}



.learningProgressBox2font {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 10px;
    padding: 3px 5px;
    width: 72px;
}

.learningProgressBox2arrow {
    width: 22px;
    padding-right: 2px;
    position: relative;
}


.timesheetVerticalLineld {
    height: 54px;
    margin: 0 15px;
    border-right: 1px dotted var(--grey);
}

.timesheetVerticalLineld1 {
    display: flex;
    height: 54px;
    margin: 0 8px;
    border-right: 1px dotted var(--grey);
}

.learningBox1 {
    height: 5rem;
    box-sizing: border-box;
    background: white;
    border-radius: 8.45708px;
    justify-content: space-around;
    margin: 0.5rem;
    flex-grow: 1;
    min-width: 512px;
    max-width: 741px !important;
    justify-content: space-around;
}



.learningImage {
    margin: 0 5px 0 11px;
    align-items: center;
    display: flex;
}

.countHolder {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.countLabel {
    color: #555555;
    font-weight: 500;
    font-size: 10px;
}

.learningCount {
    display: flex;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    color: #BFBFBF;
}

.learningCountdiv {
    display: flex;
    justify-content: space-around;
    flex: 1;
}

.learningLeft {
    display: flex;
}

.coursesContainer {

    width: 100%;
    margin-top: 29px;
    /* border: 2px solid; */
    position: relative;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-evenly;
}

.coursesHeading {
    font-size: 19px;
    font-weight: 500;
    margin-left: 30px;
}

.coursesContent {
    width: 100%;
    margin-top: 0px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-left: 17px;

}

.courseBlockHolder {
    /* width: 24rem; */
    /* height: 8rem; */
    margin: 1rem;
    margin-right: 45px;
    /* margin-right: 23px; */
    padding: 5px 0px 5px 15px;
    /* display: flex;
    justify-content: space-evenly;
    align-items: center; */
    background: #FFFFFF;
    border: 1px solid #D8F0F2;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
    contain: content;
}

.courseBlockHolder:hover {
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}


.courseIconHolder {
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary);
}

.courseDetails {
    display: flex;
    flex-direction: column;
    width: 285px;
    justify-content: space-around;
    align-items: flex-start;
    margin-left: 6px;

}

.courseHeadingContainer {
    font-weight: 500;
    padding-bottom: 0px;
    margin-bottom: 0px;
    font-size: initial;
    color: var(--primary);
    border-bottom: 1px solid #D8F0F2;
    width: 100%;
}

.courseDetailsContainer {
    display: flex;
    flex-direction: column;
    width: 94%;
}

.courseMoreDetails {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 16px;
}

.courseDetailImage {
    /* height: 20px; */
    margin-right: 10px;
}

.courseLabel {
    color: #555555;
    font-weight: 400;
    font-size: 12px;
}

.svgClass {
    stroke: #555555;
    height: 20px;
}

.labelTop {
    flex-direction: column;
    display: flex;
    width: 100%;
    margin: 22px 0px 5px 18px;
    /* height: 100%; */
    /* padding-left: 40px;
    padding-top: 45px; */
}

.learnerCardHolder {
    width: 430px;
    height: 100%;
    /* border: 2px solid; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
}

.learnerCard {
    height: 158px;
    width: 118px;
    /* border: 2px solid; */
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 24px;
}

.learnerdiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.learnerCardCover {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;

    /* margin-top: -16px;
    margin-bottom: -20px; */
}

.learnerCardContent {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 15.5px;

}

.learnerCardCoverimg {
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1; */
    width: 70px;
    height: 70px;
    border-radius: 50px;
    border: 1px solid white
}

.CandidateProfileTeamLearning {
    width: 100%;
    /* margin: 0px -15px; */
}


.learnerImageHolder {
    border-radius: 100%;
    width: 60%;
    aspect-ratio: 1;
    margin-top: 4px;
}

.learnerImageContainer {
    display: flex;
    justify-content: center;
}

.learnerBadge {
    position: absolute;
    top: -8px;
    right: -10px;
}

.medalClass {
    position: absolute;
    top: -104px;
    left: -20px;
}

.backgroundPurple {
    background-color: #8A88ED;
}

.backgroundOrange {
    background-color: #FB7158;

}

.backgroundGreen {
    background-color: #5BC4BF;

}

.avatarBadgeld {
    position: absolute;
    background-color: var(--secondary);
    margin-left: 3rem;
    margin-top: 6rem;
    z-index: 2;
    padding: 0px 10px;
    border-radius: 20px;
    color: var(--white);
    font-size: 12px;
    left: 117px;
}

.avatarContainerld {
    width: 10rem;
    height: 10rem;
    border-radius: 100%;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.05);
    contain: content;
    /* margin-left: -58px; */
}

.learningfont {
    color: var(--pms, #FB7158);
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
}

.candidateProfileContainerld {
    width: 83rem;
    overflow-y: auto;
    overflow-x: hidden;
    background-image: none;
}

.moduleMainContainerld {
    background-color: #ffffff;
    box-shadow: 0px 3px 10px rgb(0 0 0 / 6%);
    border-radius: 8px;
    margin: 12px 12px 12px 6px;
    width: 83%;
    display: flex;
    flex-direction: column;
    height: calc(100% - 24px);
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
}

.courseDetail {
    display: flex;
    align-items: center;
    width: 142px;
}

.horizontalLineld {
    margin: 6px 0px;
    border-bottom: 0.5px solid #D8F0F2;
    flex-shrink: 0;
}

.courselabeldp {
    border-radius: 50px;
    text-align: start !important;
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.VideoIconimg {
    border-radius: 10px;
    height: 107px !important;
    width: 100px !important;
    margin-right: 10px;

}

.candidateProfileHeaderSection1 {
    position: relative;
    margin: 1rem;
    align-items: center;
    display: flex;
    justify-content: space-between;
    /* flex-wrap: wrap; */
    flex-wrap: nowrap;
    height: 190px;
    overflow: hidden;
    width: 96%;
    margin: 30px;
    background: #FFFFFF;
    box-shadow: 0px 3px 15px rgb(0 0 0 / 6%);
    border-radius: 6px;
}

.candidateProfileHeaderSection1team {
    position: relative;
    margin: 1rem;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 18rem;
    overflow: hidden;
    width: 96%;
    margin: 30px;
    background: #FFFFFF;
    box-shadow: 0px 3px 15px rgb(0 0 0 / 6%);
    border-radius: 6px;
}

.candidateProfileHeaderSection2team {
    position: relative;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;
    width: 96%;
    height: 437px;
    margin: 30px;
    background: #FFFFFF;
    box-shadow: 0px 3px 15px rgb(0 0 0 / 6%);
    border-radius: 6px;
}

.candidateProfileHeaderld1 {
    position: relative;
    width: 100%;
    margin: 1rem;
    align-items: center;
    display: flex;
    justify-content: space-between;

    /* height: 16rem; */
    flex-shrink: 0;
    flex-wrap: nowrap;
}

.candidateProfileHeaderld {
    position: relative;
    width: 100%;
    margin: 1rem;
    padding: 22px;
    /* align-items: center; */
    display: flex;
    flex-direction: column;
    /* height: 16rem; */
    flex-shrink: 0;
}

.candidateProfileHeaderldcontent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.homeEngageAsideld {
    width: 304px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}

.profileImgHolderld {
    height: 100%;
    width: 254px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    left: -32px;
}

.learnerCardColorCover {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    margin-top: -6px;
    width: 87%;
}

.learnerCardColor {
    color: #FFFFFF;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    /* line-height: 16.779px; */
    letter-spacing: -0.11px;
    /* white-space: nowrap; */
    overflow: hidden;
    width: 100%;
    /* height: 28px; */
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Limit the number of lines to 2 */
    -webkit-box-orient: vertical;
    white-space: normal;
    /* Allow wrapping for two lines */
    text-overflow: ellipsis;

}

.learnerCardColortime {
    color: #FFFFFF;
    color: var(--tax-pastel, #F0F0FF);
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.779px;
    letter-spacing: -0.11px;
    white-space: nowrap;
    /* Prevent text from wrapping to the next line */
    overflow: hidden;
    /* Hide any overflow text */
    text-overflow: ellipsis;
    /* Display an ellipsis when the text overflows */
    /* Set the desired width for the element */
}

.dashboardRightld {
    background-image: url("../../Assets/MyServicesDashboardBackRight.svg");
    background-position: right;
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: center;
    position: relative;
}

.learningvidoesheading {
    background-color: white;
    width: 95%;
    padding: 12px 12px 12px 18px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
    background-color: white;
    border-radius: 8px 8px 0px 0px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
}

.learnigvideoheadingfont {
    color: var(--people-sol-dark-grey, #555);
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.32px;
    letter-spacing: 0.3px;
}

.viewsfont {
    color: var(--people-sol-dark-grey, #555);
    text-align: right;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
}

.displayblock {
    display: block !important;
}

.displaynone {
    display: none !important;
}

.timelineContainerld {
    width: 90%;
    align-self: center;
    justify-self: center;
    background: #FFFFFF;
    box-shadow: 0px 3px 15px rgb(0 0 0 / 6%);
    border-radius: 6px;
    /* background-image: url("../../Assets/RewardsBackground.svg");
    background-repeat: no-repeat; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.commentContentHolderdesign {
    border-radius: 4px;
    border: 0.75px solid var(--people-sol, #BFBFBF);
    background: #FFF;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.02);

}

.createProjectContainerCertificate {
    /* background-color: white; */
    background-image: url("../../Assets/CertificateImage.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    display: flex;
    flex-direction: column;
    border-radius: 9px;
    width: 900px;
    height: 636px;
}

.tablecontentldteam {
    height: 700px;

    /* min-width: 1172px !important; */
    overflow-x: hidden;
    overflow-y: auto;
    width: 94%;

}

.completedHeadingld {
    display: flex;
    align-items: center;
    width: 100%;
    /* min-width: 1172px !important; */
    margin: 4px 0px;
}

.completedHeadingldteam {
    display: flex;
    align-items: center;
    width: 94%;
    /* min-width: 1172px !important; */
    margin: 10px 0px 5px 0px;
}



.columnwidth {
    display: flex;
    flex: 1;
    padding: 10px;
}

.candidateProfileHeaderDiv1 {
    display: flex;
    justify-content: space-between;
    min-width: 480px;
}

/* @media screen and (min-width:1200px) and (max-width:1720px) { */
@media screen and (max-width:1720px) {

    .courseBlockHolder {
        /* width: 24rem; */
        /* height: 8rem; */
        margin: 1rem;
        /* margin-right: 45px; */
        margin-right: 23px;

        /* display: flex;
        justify-content: space-evenly;
        align-items: center; */
        background: #FFFFFF;
        border: 1px solid #D8F0F2;
        border-radius: 5px;
        contain: content;
    }

    .h4marginld {
        font-size: 15px;
    }

    .h3marginld {
        font-size: 20px;
    }

    .courseLabel {
        color: #555555;
        font-size: 12px;
        font-weight: 400;
    }

    .courseDetailImage {
        height: 13px;
        margin-right: 10px;
    }

    .courseDetails {
        display: flex;
        flex-direction: column;
        /* width: 217px; */
        justify-content: space-around;
        align-items: flex-start;
        margin-left: 6px;
    }

    .courselabeldp {
        border-radius: 50px;
        text-align: start !important;
        height: 16px;
        width: 16px;
        margin-right: 10px;
    }
}

@media screen and (min-width:1365px) and (max-width:1427px) {
    .countLabel {
        color: #555555;
        font-weight: 500;
        font-size: 9px;
    }
}

/* @media screen and (max-width:1365px) {
    .candidateProfileHeaderSection1 {
        position: relative;
        margin: 1rem;
        align-items: flex-start;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        height: 37rem;
        overflow: hidden;
        width: 97%;
        margin: 30px;
        background: #FFFFFF;
        box-shadow: 0px 3px 15px rgb(0 0 0 / 6%);
        border-radius: 6px;
    }

    .candidateProfileHeaderDiv1 {
        display: flex;
        justify-content: space-between;
        min-width: 480px;
        margin-top: 93px;
    }

    .homeGreetingContainerld {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        margin: 0px 0px 135px 0px;
    }

    .candidateProfileHeaderld1 {
        position: relative;
        width: 100%;
        margin: 1rem;
        align-items: center;
        display: flex;
        justify-content: center;
        /* height: 16rem; 
 flex-shrink: 0;
flex-wrap: wrap;
}


} */
@media screen and (max-width:1365px) {
    .profileImgHolderld {
        display: none;
    }

    .candidateProfileHeaderDiv1 {
        display: flex;
        justify-content: space-between;
        min-width: 182px;
    }
}

.certificate-powered-by {
    text-align: center;
    font-size: 13px;
}

.certificate-line {
    display: inline-block;
    width: 20px;
    margin-bottom: 5px;
    height: 1px;
    background-color: #333;
}

.courseCompletionPercentage {
    /* height: 30px; */
    background-color: #F8F8F8;
    margin: 0px 15px 7px 0px;
    border-radius: 6px;
    padding: 9px 9px 9px 9px;
}

@media screen and (max-width:1450px) {
    .mylearningdetailssection {
        width: 66%;
    }
}

.peopleSolIconCover {
    height: 37px;
    width: 106px;
    background-color: white;
    padding: 5px;
    position: absolute;
    bottom: 19px;
    right: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    z-index: 10;

}

.peopleSolIconCoverAfter {
    height: 37px;
    width: 106px;
    background-color: white;
    padding: 5px;
    position: absolute;
    bottom: 1px;
    right: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    z-index: 10;
}

.transparentLayerOnVideo {
    height: 84%;
    background-color: transparent;
    /* background-color: #5BC4BF; */
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 10;
}

.transparentLayerOnVideoAfter {
    height: 93%;
    background-color: transparent;
    /* background-color: #5BC4BF; */
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 10;
}