.myinput[type="checkbox"]:before{
    position: relative;
    display: block;
    content: "";
    background: #FFF;
}
.myinput[type="checkbox"]:after{
    position: relative;
    display: block;
    left: 2px;
    top: -11px;
    width: 7px;
    height: 7px;
    content: "";
    border: 1px solid #BABABA;
    border-radius: 3px;
    background-color: #FFF;
    background-repeat: no-repeat;
    background-position:center;
}
.myinput[type="checkbox"]:checked:after{
    background-image:  url('../../../../Assets/RightCheck.svg'), linear-gradient(135deg, #1296B0 0%,#1296B0 100%);
    border: none;
}
.myinput[type="checkbox"]:not(:disabled):checked:hover:after{
    background-image:  url('../../../../Assets/RightCheck.svg') linear-gradient(135deg, #1296B0 0%,#1296B0 100%);
    border: none;
}
.myinput[type="checkbox"]:not(:disabled):hover:after{
    background-color:#c9c9c9;  
    border: none;
}
.myinput.large[type="checkbox"]:before{
    width: 20px;
    height: 20px;
}
.myinput.large[type="checkbox"]:after{
    top: -20px;
    width: 16px;
    height: 16px;
}
.newChatContainer .forwardParticipantListContainer{
    background-color: #EFF9FA;
    position: absolute;
    bottom: 0;
    width: 100%;
    min-height: 50px;
    max-height: 100px;
    color: #1296B0;
    overflow-y: scroll;
}
.newChatContainer .forwardParticipantListContainer .sendIcon{
    position: fixed;
    bottom: 7px;
    right: 20px;
}
.newChatContainer .forwardParticipantListContainer .forwardParticipantList{
    padding: 5px 7px;
}
.newChatContainer .forwardParticipantListContainer .forwardParticipantList span{
    padding: 3px 5px;
}
.newChatContainer .forwardParticipantListContainer{
    padding: 0px !important;
}
.newChatContainer .newChatListContainer{
    padding: 12px;
    padding-bottom: 50px;
    
}