.pageContainer {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.dashboard {
  display: flex;
  height: 15rem;
  position: relative;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.dashboardLeft {
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  height: 100%;
  align-items: center;
  flex-shrink: 0;
}

.profileImg {
  margin-left: 5.5rem;
  height: 12rem;
  width: 12rem;
}

.greeting {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-left: 3rem;
  margin-top: 3rem;
  gap: 0.2rem;
}

.greetingHey {
  margin: 0;
  font-weight: 500;
  font-size: 1.5rem;
  color: var(--primary);
}

.greetingName {
  margin: 0;
  font-weight: 500;
  font-size: 1.5rem;
}

.greetingRole {
  margin: 0;
  font-weight: 500;
  font-size: 1.2rem;
}

.shortLine {
  background-color: var(--primary);
  height: 3px;
  width: 20%;
  margin-top: 1px;
  border-radius: 10px;
}

.dashboardRight {
  background-image: url("../../Assets/MyServicesDashboardBackRight.svg");
  background-position: right;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  flex-grow: 1;

  position: relative;
}

.cameraPro {
  position: absolute;
  left: 106px;
  top: 128px;
}

.sd {
  display: inline;
}

.dashboardRights {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  margin: auto;
}

.dashboardRigh {
  background-color: #fbfbfb;
  display: flex;
  margin: 0 20px;
  padding: 8px 0 0 12px;
  gap: 10px;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.teamddiv {
  margin: 12px 0px 0 0px;
  padding: 0 12px;
  flex-direction: column;
  display: flex;
  border-radius: 6px;
  flex-shrink: 0;
  contain: content;
  width: 100%;
}

.dashboardRights::before {
  content: "";
  background-image: url("../../Assets/CanInner.svg");
  z-index: 1;
  opacity: .6;
  background-repeat: no-repeat;
  background-size: cover;
  width: 20%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
}

.dashRightCont {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  gap: 6px;
}

.dashboardServices {
  display: flex;
  width: auto;
  align-items: center;
}

.dashboardService {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  gap: 22px;
}

.dashboardServicess {
  display: flex;
  width: 100%;
  gap: 15px;
}

.dashboardServicesContainer {
  display: flex;
  margin: 9px;
  align-items: center;
  width: 19rem;
  contain: content;
}

.dashboardServicesContainerss {
  display: flex;
  margin: 9px;
  padding: 0 0 0 41px;
  align-items: center;
  width: 162px;
}

.dashboardServicesContainers {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.dashboardServicesIcon {
  height: 4rem;
  width: 4rem;
}

.verticalLineGray {
  background-color: var(--grey);
  height: 60%;
  width: 2px;
  border-radius: 10px;
  margin-right: 1rem;
  margin-left: 1rem;
}

.dashboardServicesHeading {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 600;
}

.dashboardServicesName {
  margin: 0;
  font-weight: 500;
  font-size: 1.1rem;
  color: var(--secondary);
  height: 3rem;
}

.dashboardServicesNameee {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  color: var(--secondary);
}

.dashboardServicesNames {
  margin: 0;
  font-weight: 400;
  font-size: 11px;
  height: 3rem;
}

.dashdetailsNamess {
  color: var(--PeopleSOl-Primery, #1296b0);
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.dashdetailsNames {
  color: var(--PeopleSol-Dark-Grey, #555);
  font-family: "Poppins";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
}

.editPencil {
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 0.5rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 0.6rem;
  box-shadow: 0px 4px 6px rgb(85 85 85 / 15%);
}

.editPencil:hover {
  cursor: pointer;
}

.editPencilIcon {
  width: 1.2rem;
  height: 1.2rem;
}

.socialIconContainer {
  position: absolute;
  right: 0;
  bottom: -8px;
  margin: 0.5rem;
}

.socialIcon {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0.25rem;
}

.socialIcon:hover {
  cursor: pointer;
}

.chatListSearchContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
}

.chatListSearch {
  background: #eff9fa;
  border: .5px solid #d8f0f2;
  border-radius: 4.65352px;
  box-sizing: border-box;
  margin: 12px 0;
  outline: none;
  padding: 6px 12px;
  width: 93%;
}

.servicesContainer {
  width: 100%;
  display: flex;
  overflow-y: hidden;
  height: calc(100% - 15rem);
  flex-grow: 1;
}

.servicesSidebar {
  width: 22%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex-shrink: 0;
  position: relative;
}

.servicesSidebarTile {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
}

.sidebarTileSelected {
  background-color: var(--primary-hover);
}

.sidebarTileSelected>.sidebarVerticalBar {
  background-color: var(--primary);
}

.sidebarVerticalBar {
  position: absolute;
  left: 0;
  height: 100%;
  width: 10px;
  background-color: transparent;
}

.servicesSidebarTitleLabel {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.servicesMain {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.profileSubSection {
  width: 100%;
  overflow: auto;
  height: 94%;
}

.servicesContentHolder {
  height: 100%;
  width: 78%;
  display: flex;
  flex-direction: column;
}

.dashcont {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hideDashboard {
  display: none;
  position: relative;
}

.viewDashboard {
  display: flex;
  position: relative;
}

.searchService {
  width: 20rem;
  left: 7px;
  border: 2px solid black;
  top: 3rem;
  z-index: 2;
  max-height: 6rem;
  height: fit-content;
}