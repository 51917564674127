.whatsNewMainContainer{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: calc(100% - 10rem);
}
.newHeadingContainer{
    display: flex;
    flex-direction: column;
    margin: 3rem 0;
}
/* .whatsTabContainer > div{
    padding-left: 30rem;
} */
.allMainContainer{
    display: flex;
    height: 100%;
    width: 100%;
}
.allContentHolder{
    /* border: 1px solid black; */
    height: 100%;
    /* width: 50%; */
}
.rightContent{
    display: flex;
    contain: content;
    overflow: auto;
    position: relative;
    width: 100%;
}
.rightInfoHolder{
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
}
.rightBottom{
    display: flex;
    flex-direction: column;
    line-height: 26px;
    padding-top: 5px;
}
.rightBottom > label {
    font-size: 16px;
    line-height: 44px;
}
.sideEnhanceText{
    border-radius: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
    font-size: 13px;
    width: 8rem;
    color: white;
}
.newContentMainHolder{
    border-bottom: 1px solid lightgray;
    padding-bottom: 20px;
    padding-top: 20px;
    position: relative;
    margin-left: 50px;
}
.lineBesideHeading{
    position: absolute;
    left: -40px;
    top: 3px;
}
.leftContent{
    display: flex;
    width: 30%;
}
.ddHolderLeft{
    display: flex;
    flex-direction: column;
    width: calc(100% - 100px);
    padding: 50px;
}
.leftInfo{
    position: absolute;
    right: 10px;
    text-align: center;
    top: 22px;
    background-color: #8A88ED;
    border-radius: 10px;
    display: flex;
    height: 40px;
    font-size: 14px;
    width: 115px;
    justify-content: space-around;
    align-items: center;
    color: white;
}
.leftInfoHolder{
    width: 100px;
    position: relative;
}
.verticalNewGreyLine{
    position: absolute;
    width: 1px;
    height: 100%;
    left: -40px;
    background-color: lightgrey;                          
}
.ddContent{
    width: calc(100% - 24px);
    height: 45px;
    background: rgb(138, 136, 237);
    border-radius: 5px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 17px;
}
.ddMainContainer{
    height: fit-content;
    max-height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.ddContentDisplayer{
    height: calc(100% - 42px);
    width: calc(100% - 24px);
    flex-direction: column;
    contain: content;
    overflow: auto;
    border: 1px solid #8A88ED;
    border-radius: 0 0 5px 5px;
    line-height: 35px;
    display: none;
}
.dis{
    height: calc(100% - 42px);
    flex-direction: column;
    contain: content;
    overflow: auto;
    border-radius: 0 0 5px 5px;
    line-height: 35px;
}
.ddContentDisplayer > label{
    padding-left: 15px;
    font-size: 13px;
    font-weight: 400;
}
.visibleClass{
    display: flex;
}
.borderClass{
    border-radius: 5px 5px 0 0;
}