.childrenBlock {
  border: 2px solid #D8F0F2;
  height: inherit;
  border-radius: 5px;
  background-color: white;
  display: flex;
  align-items: center;
  position: relative;
  width: calc(100% - 60px);
}

.orgDataHolder {
  display: flex;
  flex-direction: column;
  width: 70%;
  padding-left: 18px;
}

.orgImageHolder {
  width: 25%;
  display: flex;
  justify-content: center;
  border-radius: 100%;
  contain: content;
  margin-left: 17px;
  height: calc(100% - 23px);
}

.parentNodeClass {
  background: #dedede;
}

.childrenCountClass {
  position: absolute;
  top: 35px;
  right: -43px;
}

.childrenCountClass>label {
  background: #8A88ED;
  color: white;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  align-items: center;
  min-width: 20px;
}

.blockHover:hover {
  background: #e5f1f9;
  transition: 0.6s;
}

.verticalCountClass {
  right: 128px;
  top: 105px;
}

.verticalChildrenBlock {
  height: calc(100% - 40px);
}

.viewChanger {
  position: absolute;
  top: 22px;
  right: 29px;
  padding: 2px 4px;
  border-radius: 5px;
}

.rd3t-link {
  stroke: #BFBFBF !important;
}

.childDisplayText {
  background: #e5f1f9;
  border-radius: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: var(--primary);
  font-weight: 500;
}

.orgTreeClass {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.svgorgstructure>g {
  transform: translate(648.5px, 130.5px) !important;

}