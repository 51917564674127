.countItem{
    font-size: 25px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    
}
.countDetails{
    display: flex;
    flex-direction: column;
    text-align: center;
}