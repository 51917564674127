.leaveProgressContainer > label {
  /* color: #5991C2; */
  font-weight: 300;
  font-size: 12px;
}
.leaveProgressContainer {
  width: 120px;
  text-align: center;
}
.leaveBalanceInfoHolder {
  display: flex;
  flex-direction: column;
  width: 33.3333333%;
  align-items: center;
  padding: 6px;
}
.leaveBalanceCount {
  font-size: 36px;
  font-weight: 500;
  height: 56px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
.noMarginTop {
  margin-top: 0;
}
.leaveNameHolder {
  padding-top: 5px;
  font-weight: 500;
  width: 75%;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 12px;
}
