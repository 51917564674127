.salaryStructureContainer {
  height: 100%;
  width: 40%;
  background-color: white;
  z-index: 11;
  display: flex;
  flex-direction: column;
}
.salaryStructureHeader {
  background-color: var(--primary-hover);
  padding: 48px 24px 24px 24px;
  flex-shrink: 0;
}
.salaryStructureBody {
  padding: 24px;
  flex-grow: 1;
  height:calc(100% - 218px);
  overflow-y: auto;
  overflow-x: hidden;
}
.salaryStructureHeadingContainer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 6px;
  border-bottom: 2px solid var(--grey);
}
.addSalaryCompButton {
  background-color: var(--primary-hover);
  color: var(--primary);
  padding: 3px 6px;
  border-radius: 6px;
  font-weight: 500;
}
.salaryStructureLineContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 0;
}
