.homeEngageMainAsideContainer
  .homeEngageMainAsideContainerHolder
  .engageMainAsideGroupsContainer
  .communityHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
}
.homeEngageMainAsideContainer
  .homeEngageMainAsideContainerHolder
  .engageMainAsideGroupsContainer
  .communityHeaderContainer
  span {
  height: 13px;
  color: var(--people-s-ol-primery, #1296b0);
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  cursor: pointer;
}
.communityHomeEngageContainer .homeEngageMainAsideContainer {
  min-width: 100% !important;
  max-width: 100% !important;
}
.communityHeaderImgContainer {
  cursor: pointer;
}
.homeEngageMainAsideContainerHolder {
  width: 100%;
  height: 500px;
  background: var(--primary-hover);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  contain: content;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 12px;
  margin: 6px;
}
.engageMainAsideGroupsContainer {
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #f1f2f2;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
}

.communityBodyContainer {
  overflow-y: auto;
  flex: 1 0 0;
}
.communityBodyContainer::-webkit-scrollbar-button:single-button {
  display: block;
  background-size: 7px;
}
.communityBodyContainer::-webkit-scrollbar-button:single-button:increment {
  height: 12px;
  width: 16px;
  background-repeat: no-repeat;
  background-position: center 4px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(231, 231, 231)'><polygon points='0,0 100,0 50,50'/></svg>");
}
.communityBodyContainer::-webkit-scrollbar-button:single-button:decrement {
  height: 12px;
  width: 16px;
  background-repeat: no-repeat;
  background-position: center 4px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(231, 231, 231)'><polygon points='50,00 0,50 100,50'/></svg>");
}

.communityBodyContainer::-webkit-scrollbar {
  width: 13px;
}

.communityBodyContainer::-webkit-scrollbar-track {
  background: #fff;
}

.communityBodyContainer::-webkit-scrollbar-thumb {
  border: 5px solid #fff;
  background-color: #e7e7e7;
  border-radius: 15px;
}

.communityBodyWrapper {
  border-top: 1px solid #f1f2f2;
}
.communityBodyWrapper .communityListContainer {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.communityBodyWrapper .communityListContainer .communityListWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.communityBodyWrapper
  .communityListContainer
  .communityListWrapper
  .communityListImgContainer
  img {
  height: 45px;
  width: 45px;
  border-radius: 100%;
}
.socialCommunityProfilePicContainer {
  height: 45px;
  width: 45px;
  margin-right: 12px;
}
.communityBodyWrapper
  .communityListContainer
  .communityListWrapper
  .communityListTextContainer {
  width: 80%;
}
.communityBodyWrapper
  .communityListContainer
  .communityListWrapper
  .communityListTextContainer
  h5 {
  color: var(--people-sol-dark-grey, #555);
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
}
.communityBodyWrapper
  .communityListContainer
  .communityListWrapper
  .communityListTextContainer
  p {
  color: var(--people-sol-dark-grey, #555);
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
}
.communityBodyWrapper .communityListContainer .CommunityListDateTimeWrapper {
  color: var(--people-sol-dark-grey, #555);
  text-align: right;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}

.communityBodyWrapper
  .communityListContainer
  .CommunityListDateTimeWrapper
  span {
  white-space: pre;
}

.communityListCreateWrapper {
  border-bottom: 1px solid #f1f2f2;
}
.communityListCreateWrapper .communityListContainer {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.communityListCreateWrapper .communityListContainer .communityListWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.communityListCreateWrapper
  .communityListContainer
  .communityListWrapper
  .communityListImgContainer
  img {
  height: 40px;
  width: 40px;
  border-radius: 100%;
}
.communityListCreateWrapper
  .communityListContainer
  .communityListWrapper
  .communityListTextContainer {
  width: 80%;
}
.communityListCreateWrapper
  .communityListContainer
  .communityListWrapper
  .communityListTextContainer
  h5 {
  color: var(--people-sol-dark-grey, #555);
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  /* line-height: 13px; */
}
.communityListCreateWrapper
  .communityListContainer
  .communityListWrapper
  .communityListTextContainer
  p {
  color: var(--people-sol-dark-grey, #555);
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
}
.communityListCreateWrapper
  .communityListContainer
  .CommunityListDateTimeWrapper {
  color: var(--people-sol-dark-grey, #555);
  text-align: right;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}

.communityListCreateWrapper
  .communityListContainer
  .CommunityListDateTimeWrapper
  span {
  white-space: pre;
}
.SelectCommunityMembers1 {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  left: 0;
  justify-content: center;
  z-index: 999;
}
.SelectCommunityMembers2 {
  display: flex;
  contain: content;
  position: relative;
  height: -moz-fit-content;
  height: fit-content;
  background: #fff;
  border-radius: 8px;
  border: none;
  border-radius: 8px;
  max-height: 620px;
  min-height: 620px;
  max-width: 550px;
  min-width: 550px;
  margin-top: 40px;
}

.SelectCommunityMembersBody {
  overflow-x: hidden;
}
.SelectCommunityMembersBody::-webkit-scrollbar {
  display: none;
}
.communityEmployeeSearchContainer {
  position: relative;
  min-height: 15px;
  max-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #eff9fa;
  border: 0.5px solid #d8f0f2;
  border-radius: 4.65352px;
  overflow: auto;
  position: sticky;
  margin: 10px;
}
.communityEmployeeSearchContainer .chatListSearch {
  box-sizing: border-box;
  margin: 0 !important;
  padding: 5px 10px;
  outline: none;
  border: none;
  width: 100%;
}
.communitySelectedEmployeeContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.newCommunityContainer .communitySelectChipContainer {
  display: flex;
  background-color: #fff;
  padding: 3px;
  border-radius: 30px;
  position: relative;
  align-items: center;
  margin: 1.5px 2.5px;
}
.newCommunityContainer .communitySelectChipContainer > svg {
  cursor: pointer;
}
.newCommunityContainer .chipImg {
  width: 28px;
  height: 28px;
  border-radius: 100px;
}
.newCommunityContainer .chipLabel {
  color: #555;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin: 7px 10px;
  margin-right: 20px;
}
.newCommunityContainer .communityAllEmployeeListContainer {
  display: flex;
  align-items: center;
  padding: 10px 0px;
}
.newCommunityContainer .communityAllEmployeeListContainer .newChatActiveImage {
  height: 45px;
  width: 45px;
  border-radius: 100%;
  margin: 12px;
  position: relative;
}
.newCommunityContainer
  .communityAllEmployeeListContainer
  .newChatActiveImage
  > img {
  height: 100%;
  width: 100%;
  border-radius: 100%;
}
.newCommunityContainer
  .communityAllEmployeeListContainer
  .newChatPostMainTextContainer {
  display: flex;
  align-items: start;
  flex-direction: column;
}
.newCommunityContainer
  .communityAllEmployeeListContainer
  .newChatPostMainTextContainer
  .chatListName {
  font-size: 15px !important;
  color: black;
  font-weight: 500;
}
.newCommunityContainer
  .communityAllEmployeeListContainer
  .newChatPostMainTextContainer
  .chatListRole {
  font-size: 13px;
  color: rgb(114, 111, 111);
  font-weight: 500;
}
.newCommunityContainer .communityAllEmployeeListContainer:active {
  background-color: rgba(216, 240, 242, 0.4);
}
.newCommunityContainer .communityAllEmployeeListContainer:active .chatListName {
  color: var(--primary);
}
.communityListSearch {
  border-bottom: 1px solid #6bd8ee;
}
.newCommunityContainer .groupCreationNxtBtn {
  position: absolute;
  bottom: 20px;
  right: 25px;
  cursor: pointer;
}
.SelectCommunityMembersBody .popupContentHolder {
  display: block;
  padding: 0;
}
.createCommunityBottomconatiner {
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.communityHeaderContainer .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  border-radius: 5px;
}
.createCommunityDefaultProfileImgContainer {
  position: relative;
  left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}
.createCommunityImageInputContainer {
  width: fit-content;
  position: relative;
  top: 50px;
  right: 60px;
  z-index: 999;
}
.createCommunityTextContainer label {
  color: #1296b0;
  font-weight: 500;
}
.createCommunityTextContainer input,
.createCommunityTextContainer textarea {
  border: 1px solid #d8f0f2;
  outline: none;
}
.createCommunityTextContainer input:focus {
  border: 1px solid #d8f0f2;
  outline: none;
}
.communityMemberListBox {
  /* overflow: auto;
  height: 500px;
  padding-bottom: 200px; */
  padding-left: 10px;
}
.createCommunityProfileImgContainer img {
  top: 6px;
  left: 27px;
  height: 112px;
  width: 112px;
  object-fit: cover;
  border-radius: 100%;
  position: relative;
}
.createCommunityProfileImgContainer {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  position: absolute;
  left: 190px;
  top: 110px;
}
.editCommunityProfileImgContainer img {
  bottom: 78px;
  left: 12.2px;
  height: 112px;
  width: 112px;
  object-fit: cover;
  border-radius: 100%;
  position: relative;
}
.editCommunityProfileImgContainer {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  position: absolute;
  left: 190px;
  top: 110px;
}
.CreateCommunityPopupBox1 {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  left: 0;
  justify-content: center;
  z-index: 999;
}
.CreateCommunityPopupBox2 {
  display: flex;
  contain: content;
  position: relative;
  height: -moz-fit-content;
  height: fit-content;
  background: #fff;
  border-radius: 8px;
  border: none;
  border-radius: 8px;
  max-height: 630px;
  min-height: 630px;
  max-width: 500px;
  min-width: 500px;
  margin-top: 40px;
}
.communityViewPageContainer {
  position: relative;
  width: 93%;
  margin: 0 4% 0 4.7%;
  border-radius: 8px;
  background: var(--white, #fff);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.06);
}
.viewPageHeaderImg {
  width: 100%;
}
.communityProfileContainer {
  border-bottom: 1px solid #eee;
  height: 160px;
}
.communityProfileContainer .communityProfileImgContainer {
  width: 150px;
  height: 150px;
  border: 10px solid white;
  border-radius: 100%;
  position: absolute;
  top: 12%;
  left: 5%;
  box-shadow: 0px -1px 10px 0px rgba(0, 0, 0, 0.06);
  background-color: white;
}
.communityProfileContainer .communityExitOrLeaveContainer {
  position: absolute;
  top: 220px;
  padding: 0 25px;
  width: 100%;
}
.communityProfileContainer .communityExitOrLeaveWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.communityAboutContainer {
  padding: 20px;
}
.communityProfileContainer h4 {
  color: #1296b0;
}
.communityAboutWrapper {
  margin-bottom: 15px;
}
.communityAboutWrapper h5 {
  color: #1296b0;
}
.communityAboutText p {
  color: #555;
}
.communityAboutText span {
  color: #bfbfbf;
  font-family: Poppins;
  font-size: 12px;
}
.communityAdminMemberContainer {
  margin: 6px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.06);
}
.communityAdminMemberWrapper {
  padding: 10px 10px;
}
.communityAdminListContainer {
  background-color: #effbfc;
  border-radius: 8px;
  padding: 10px 0px;
}
.communityAdminList {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding: 10px;
}
.communityAdminPrifileContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.communityAdminList img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-right: 10px;
}
.communityAdminContainer h4,
.communityMemberContainer h4 {
  color: #1296b0;
  font-size: 16px;
  margin-bottom: 7px;
}
.communityAdminContainer {
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.communityMemberContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.communityMemberBtn button {
  border: none;
  padding: 7px 20px;
  color: #1296b0;
  background-color: #d8f0f2;
  font-weight: 500;
  border-radius: 5px;
}
.communityAdminBtn button {
  border: none;
  padding: 7px 20px;
  color: #ffb703;
  background-color: #fff4e5;
  font-weight: 500;
  border-radius: 5px;
}
.communityAdminPrifileWrapper h6 {
  color: #1296b0;
}
.communityAdminPrifileWrapper p {
  color: #555;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
}
.CommunityMemberImgList {
  display: flex;
  align-items: center;
}
.CommunityMemberImgList img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 1px solid white;
  margin-top: 20px;
}
.CommunityMemberImgList img:nth-child(2) {
  position: relative;
  left: -12px;
}
.CommunityMemberImgList img:nth-child(3) {
  position: relative;
  left: -22px;
}
.CommunityMemberImgList img:nth-child(4) {
  position: relative;
  left: -32px;
}
.CommunityMemberImgList span {
  color: #1296b0;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}
.makeRemoveAdminOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.makeRemoveAdminOption span {
  font-size: 13px;
  font-weight: 500;
  color: #1296b0;
  margin-right: 20px;
  border-radius: 7px;
  padding: 5px 10px;
  background-color: #d8f0f2;
}
.makeRemoveAdminActivityHolder {
  border: none;
  border-radius: 6px;
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 999;
  top: 30px;
  right: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.makeRemoveAdminActivityHolder .headingFilterHeader h5 {
  width: 130px;
  font-size: 13px;
  color: rgb(95, 95, 95);
}
.makeRemoveAdminActivityHolder .headingFilterHeader {
  cursor: pointer;
  text-align: start;
}
.makeRemoveAdminActivityHolder .headingFilterHeader:hover {
  background-color: rgba(216, 240, 242, 0.4);
}
.communityLeaveDeactivePopupBox1 {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  left: 0;
  justify-content: center;
  z-index: 999;
}
.communityLeaveDeactivePopupBox2 {
  display: flex;
  flex-direction: column;
  contain: content;
  position: relative;
  left: -40px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #999;
  max-height: 85%;
  border-radius: 6px;
  min-height: 240px;
  min-width: 500px;
  max-width: 500px;
  margin-top: 20px;
}
.communityLeaveDeactivePopupBox2 .popupContentHolder {
  padding: 12px;
  font-size: 14px;
  display: block;
}
.communityLeaveDeactiveBody {
  margin-bottom: 50px;
  padding-top: 10px;
}
.communityViewPageBackeBtn {
  position: absolute;
  top: 5px;
  left: 40px;
}
.communityViewPageBackeBtn span {
  color: #1296b0;
  font-size: 13px;
  font-family: "Poppins";
  padding-top: 2px;
}
.myCommunityListContainer {
  width: 100%;
  margin: 0 30px;
}
.myCommunitySearchContainer {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}
.communityListSearch {
  box-sizing: border-box;
  background: #eff9fa;
  border: 0.5px solid #d8f0f2;
  border-radius: 7.5px;
  width: 100%;
  padding: 10px 12px;
  outline: none;
}
.myCommunitySearchContainer > img {
  position: absolute;
  right: 5%;
  width: 18px;
  height: 18px;
}
.myCommunityListWrapper {
  border-radius: 8px 8px 0px 0px;
  background: var(--white, #fff);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
}
.myCommunityListHeaderContainer {
  padding: 17px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px 8px 0px 0px;
  background: var(--white, #fff);
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.05);
}
.showCommunityLeftHeaderContainer {
  color: #1296b0;
  font-weight: 500;
  font-size: 15px;
}
.showCommunityRightHeaderContainer {
  border-radius: 5px;
  cursor: pointer;
  background: var(--Travel---Pastel, #fff4e5);
}
.showCommunityRightHeaderContainer span {
  color: #f7941d;
  font-size: 13px;
  border-radius: 4px;
}
.showCommunityRightHeaderContainer
  .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  border-radius: 0 !important;
}
.myCommunityListLeaveExitGroupContainer {
  display: flex;
}
.myCommunityListLeaveExitGroupContainer .leaveGroupWrapper,
.myCommunityListLeaveExitGroupContainer .deactivateGroupWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.myCommunityListLeaveExitGroupContainer .leaveGroupWrapper span,
.myCommunityListLeaveExitGroupContainer .deactivateGroupWrapper span {
  margin-right: 10px;
}
.myCommunityListLeaveExitGroupContainer .leaveGroupWrapper {
  background: #fff5f5;
  color: #f71d1d;
  padding: 6px 10px;
  border-radius: 5px;
  font-weight: 500;
}
.myCommunityListLeaveExitGroupContainer .deactivateGroupWrapper {
  background: var(--Deactive-pastel, #f4eaf3);
  color: #b69fb3;
  padding: 7px 10px;
  border-radius: 5px;
  margin-right: 10px;
}
.myCommunityListLeaveExitGroupWrapper {
  display: flex;
}
.myCommunityListBodyContainer {
  height: 650px;
  overflow-y: auto;
  padding-bottom: 220px;
}
.myCommunityListGroupImgContainer {
  width: 55px;
  height: 55px;
}
.myCommunityListGroupImgContainer img {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 100%;
  width: 100%;
  height: 100%;
}
.myCommunityListGroupContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.myCommunityListGroupWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.myCommunityListBodyWrapper {
  padding: 20px;
  border-bottom: 1px solid #f1f2f2;
}
.myCommunityListTextContainer {
  margin-left: 15px;
}
.myCommunityListTextContainer h5 {
  color: var(--PeopleSol-Text, #555);
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}
.myCommunityListTextContainer p {
  color: #bfbfbf;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
}

.newCommunityContainer .communityGroupEmployeeListContainer {
  display: flex;
  align-items: center;
  padding: 10px 0px;
}
.newCommunityContainer
  .communityGroupEmployeeListContainer
  .newChatActiveImage {
  height: 45px;
  width: 45px;
  border-radius: 100%;
  margin: 12px;
  position: relative;
}
.newCommunityContainer
  .communityGroupEmployeeListContainer
  .newChatActiveImage
  > img {
  height: 100%;
  width: 100%;
  border-radius: 100%;
}
.newCommunityContainer
  .communityGroupEmployeeListContainer
  .newChatPostMainTextContainer {
  display: flex;
  align-items: start;
  flex-direction: column;
}
.newCommunityContainer
  .communityGroupEmployeeListContainer
  .newChatPostMainTextContainer
  .chatListName {
  font-size: 15px !important;
  color: black;
  font-weight: 500;
}
.newCommunityContainer
  .communityGroupEmployeeListContainer
  .newChatPostMainTextContainer
  .chatListRole {
  font-size: 13px;
  color: rgb(114, 111, 111);
  font-weight: 500;
}
.communityEditProfileImg {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  position: absolute;
  left: 197px;
  bottom: 29px;
}
.noPostFoundContainer,
.noCommunityFoundContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
}
.noPostFoundWrapper,
.noCommunityFoundWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
}
.noPostFoundWrapper h5,
.noPostFoundWrapper span,
.noCommunityFoundWrapper h5,
.noCommunityFoundWrapper span {
  color: #a1a1a1;
}
.noPostFoundWrapper span,
.noCommunityFoundWrapper span {
  font-size: 12px;
}
.noCommunityFoundWrapper h5 {
  font-size: 15px;
}

.socialCommunityCustomProfilePic {
  color: var(--primary);
  margin-right: 12px;
  width: 45px;
  height: 45px;
  font-size: 20px;
  border-radius: 100%;
  font-weight: 500;
  background-color: #e9f3f4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.communityViewPageCustomProfilePic {
  color: var(--primary);
  width: 150px;
  height: 150px;
  border: 10px solid white;
  border-radius: 100%;
  position: absolute;
  top: 14%;
  left: 5%;
  box-shadow: 0px -1px 10px 0px rgba(0, 0, 0, 0.06);
  background-color: #e9f3f4;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
}
.socialCommunityListCustomProfilePic {
  color: var(--primary);
  width: 55px;
  height: 55px;
  font-size: 25px;
  border-radius: 100%;
  font-weight: 500;
  background-color: #e9f3f4;
  display: flex;
  align-items: center;
  justify-content: center;
}
