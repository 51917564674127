.resetBottomContainer {
  padding: 0px !important;
}
.resetPwdCancelBtn {
  border-radius: 3px;
  background: var(--White, #fff);
  box-shadow: 0px 4px 8px 0px rgba(207, 207, 207, 0.4),
    0px 1px 10px 0px rgba(0, 0, 0, 0.05) inset;
    color: red;
    padding: 7px 12px;
}
