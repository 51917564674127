.moduleMainContentHolder {
  overflow: auto;
  display: flex;
  padding: 18px 6px 6px 6px;
}

.internalJobContainer {
  background-color: white;
  height: 100px;
  border-radius: 6px;
  margin: 6px;
  box-shadow: 0px 3px 15px rgb(0 0 0 / 10%);
  width: 33.33333%;
}

.internalJobDateTime {
  font-size: 12px;
  color: var(--secondary-text);
}

.jobDetailContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 50%;
  padding: 12px 12px 0 24px;
  justify-content: flex-end;
}

.jobDetailContainer > .verticalLine {
  width: 2.1px;
  position: absolute;
  left: 12px;
  height: 70%;
}
.jobDetailContainerHolder {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 6px;
}
.jobDesignation {
  font-size: 10px;
}
.internalJobCard {
  aspect-ratio: 0;
}
.applyJobButtonContainer {
  display: flex;
  justify-content: end;
  margin-top: auto;
}
.tealBackground {
  background-color: var(--teal) !important;
}
.purpleBackground {
  background-color: var(--purple) !important;
}
.pinkBackground {
  background-color: var(--pink) !important;
}
.orangeBackground {
  background-color: var(--orange) !important;
}
