.certificatetemplate {
  display: flex;
  flex-direction: column;
  contain: content;
  position: relative;
  height: fit-content;
  background: #faf9f8;
  height: 816px;
  width: 1056px;
  overflow: auto;
  overflow-x: hidden;
  scale: 0.8;
}
.third-crt {
  position: relative;
}
.third-crt .certificatetemplate h2 {
  text-transform: uppercase !important;
}
.certif-bg {
  background: #fff url("../../../Assets/bg-certificate.svg") no-repeat;
  background-position: top 0 right;
}
.reward-details h3 {
  font-size: 43px;
  color: #194f78;
  margin: 15px auto 45px;
  text-align: center !important;
  font-weight: 600;
}

.certificatetemplate h2 {
  font-size: 70px;
  color: #fff;
  text-align: center;
  text-align: center;
  line-height: 1;
}
.reward-details p {
  color: #555555;
  font-size: 20px;
  margin: 15px auto;
  text-align: center !important;
}
.certif-bg .reward-details p {
  margin: 1% 14%;
}
.reward-details h1 {
  text-align: center;
  margin: 10px auto;
  background: -webkit-linear-gradient(
    to right,
    #194f78 0%,
    #194f78 15%,
    #c81b74 100%
  ); /* For Safari */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    to right,
    #194f78 0%,
    #194f78 15%,
    #c81b74 100%
  ); /* For other browsers */
  background-clip: text;
  color: transparent;
  font-weight: 600;
  font-size: 56px;
  line-height: 1;
}
.reward-detail {
  text-align: center;
}
.sign-box {
  text-align: center;
}

.sign-box span {
  border-bottom: 3px solid #bfbfbf;
  width: 155px !important;
  display: block;
  margin: auto;
  padding: 8px;
  margin-bottom: 10px;
}

.sign-box p {
  color: #555555;
  font-size: 18px;
}

.sign-box h5 {
  color: #194f78;
  font-size: 25px;
}
.sign-box {
  margin-top: 15%;
}
/* 
.bottom-br {
  position: absolute;
  bottom: 0;
  width: 100%;
} */
.third-crt .reward-details p {
  margin: 2% 13%;
}
.third-crt .reward-details h3 {
  display: block;
  margin: auto;
}

.WrappeCrt-bg {
  display: block;
  background: rgb(25, 79, 120);
  background: linear-gradient(
    90deg,
    rgba(25, 79, 120, 1) 0%,
    rgba(200, 27, 116, 1) 100%
  );
  padding: 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.third-crt .reward-details h3 span,
.second-crt .reward-details h3 span {
  border-bottom: 3px solid #bfbfbf;
  width: 540px;
  display: block;
  text-align: center;
  margin: auto;
}

.certif4-bg,
.certif6-bg {
  background: #fff url("../../../Assets/bg-certi4.svg") no-repeat;
  background-position: left 0 bottom;
}
.certif5-bg {
  background: #fff url("../../../Assets/bg-certi5.svg") no-repeat;
  background-position: top 0 right;
}
.certificate4-flex {
  display: flex;
  margin-top: 55px;
  justify-content: space-between;
}

.certificate4-flex h2 {
  padding: 10px 100px;
}
.certificate6-flex h3 {
  text-align: right !important;
}
.certif5-bg h1 span {
  background: -webkit-linear-gradient(
    to right,
    #194f78 0%,
    #194f78 15%,
    #c81b74 100%
  ); /* For Safari */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(to right, #194f78 0%, #194f78 15%, #c81b74 100%);
  width: 480px;
  display: block;
  text-align: left;
  height: 4px;
}

.certif5-bg .reward-details h1 {
  text-align: left !important;
  padding: 15px 52px;
  line-height: 1.3 !important;
}
.certif5-bg .reward-details p {
  margin: 0;
  padding: 0px 52px;
  text-align: left !important;
}

.certif5-bg .reward-details h3 {
  padding: 4px 52px 4px;
  margin: 0;
  text-align: left !important;
}
.certif5-bg .reward-details h1 {
  background: -webkit-linear-gradient(
    to right,
    #194f78 0%,
    #c81b74 50%,
    #c81b74 100%
  ); /* For Safari */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    to right,
    #194f78 0%,
    #c81b74 50%,
    #c81b74 100%
  ); /* For other browsers */
  background-clip: text;
  color: transparent;
  font-weight: 600;
  font-size: 56px;
  line-height: 1;
}

.second-crt h2 {
  font-size: 48px;
  color: #c81b74;
  text-transform: uppercase;
  font-weight: 600;
}
.second-crt h3 {
  font-style: italic;
}

.second-crt .reward-details {
  margin-top: 45px;
}

.Wrapperbg {
  display: block;
  background: url("../../../Assets/bg-certi2.svg") no-repeat;
  border-radius: 50px 0px 50px 0px;
  padding: 2%;
  margin: 3%;
  text-align: center;
  background-size: cover;
}
.second-crt .reward-details p {
  margin: 15px 60px;
}
.certif4-bg .reward-details h3 {
  text-align: right !important;
}
.certif4-bg .reward-details h1 {
  font-style: italic;
}

.certif4-bg .reward-details p,
.certif4-bg .reward-details h1,
.certif6-bg .reward-details p,
.certif6-bg .reward-details h1 {
  text-align: left !important;
  padding: 0% 20% 0 9%;
}

.certif5-bg .reward-details p,
.certif5-bg .reward-details h3 {
  text-align: left !important;
  padding: 0% 20% 0 5%;
}

.certificat6-flex {
  background-image: linear-gradient(
    to left bottom,
    #cbe9ff 0%,
    #cbe9ff 9%,
    #cbe9ff 18%,
    #cbe9ff 27%,
    #cbe9ff 36%,
    #d0e8ff 45%,
    #d4e7ff 54%,
    #d9e6ff 63%,
    #e4e4fd 72%,
    #eee3fa 81%,
    #f6e2f5 90%,
    #fce2ef 100%
  );

  display: flex;
  margin: 44px;
  padding: 28px;
  gap: 75px;
  align-items: center;
}
.certif6-bg .reward-details h2 span:first-child {
  font-size: 51px;
  color: #194f78;
  text-transform: uppercase;
  font-weight: 700;
}
.certif6-bg .reward-details h2 {
  text-align: left !important;
}
.certif6-bg .reward-details h2 span:nth-child(2) {
  font-size: 51px;
  color: #194f78;
  text-transform: uppercase;
  font-style: italic;
  font-family: "Poppins", sans-serif;
  font-weight: 100;
}
.certif6-bg h1 {
  text-align: center;
  margin: 10px auto;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(to right, #194f78 0%, #c81b74 50%, #c81b74 100%);
  background-clip: text;
  color: transparent;
  font-weight: 600;
  font-size: 42px;
  line-height: 1;
}
