.progressHolder {
  display: flex;
  align-items: center;
  margin-left: 10px;
  text-align: center;
}
.statusHolder {
  display: flex;
  align-items: center;
  /* margin-left: 10px; */
  text-align: center;
  min-width: 92px;
  padding: 1px;
}

/* .goalsActionsHolder > img{
    width: 40%;
} */
.progressText {
  font-weight: 500;
  font-size: 12px;
  color: #f7941d;
  background: #fdead2;
  border-radius: 4px;
  width: 57%;
  padding-left: 2px;
  margin-left: 7px;
}
.statusText {
  font-weight: 500;
  font-size: 12px;
  border-radius: 4px;
  width: 93%;
}
.yettostart {
  color: #1296b0;
  background: #d8f0f2;
  padding: 0 3px;
}
.complete {
  color: #0db53c;
  background: #ccfdda;
}
.delay {
  color: #f71d1d;
  background: #fdd2d2;
}

.taskItem {
  width: 25%;
}

.projectNameItem {
  width: 28%;
  word-wrap: break-word;
}
