.calendarHeader {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.9px solid #e6e6e6;
}

.calendarHeaderToday {
  color: #555;
  text-align: center;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.297px;
  /* 182.969% */
  letter-spacing: 0.2px;
  cursor: pointer;
}

.calendarHeaderTodayList {
  color: #555;
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.297px;
  /* 182.969% */
  letter-spacing: 0.2px;
  cursor: pointer;
}

.ContainerList > .calendarHeaderDay {
  color: #555;
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.297px;
  /* 182.969% */
  letter-spacing: 0.2px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.calendarHeaderDay {
  color: #555;
  text-align: center;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.297px;
  /* 182.969% */
  letter-spacing: 0.2px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.calendarSubHeader {
  display: flex;
  justify-content: space-around;
}

.headeractive {
  border-bottom: 1.8px solid #1296b0;
}

.calendarTitleDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 13px;
  margin-bottom: 18px;
}

.calendarTitleBtn {
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
}

#calendarTitle {
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 18.297px;
}

.calendarDesign {
  padding: 10px 17px;
  z-index: 0;
  background-color: white;
  width: 95%;
  margin-right: auto;
  margin-left: auto;
  border-radius: 10px;
}

.calanderDisplayNone {
  display: none;
}

.listDisplay {
  width: 100%;
  min-height: 401px;
  height: 100%;
}

.custom-event-class {
  /* padding: 6px 3px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #1296b0 !important;
  border: 1px #1296b0 solid;
  color: white;
  pointer-events: none;
}

.fc-timegrid-event-harness {
  position: absolute;
  background-color: #1296b0;
  border-radius: 7px;
  padding: 2px;
  border: 1px white solid;
}

.custom-event-class:hover {
  padding: 6px 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #1296b0;
  color: rgb(177, 63, 63);
  /* pointer-events: none; */
}

.custom-event > div {
  font-family: Poppins;
  font-size: 11.5px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
}

.fc table {
  background-color: #faf9f8;
}

/* Event background color */
.fc-day-today {
  background-color: #daebff !important;
}

.meetingroomformcalander {
  grid-row: 2 / span 5;
  grid-column: 2;
}

.meetingroomformdate {
  display: flex;
}

.meetingroomformdate > div {
  display: flex;
}

.radioformikstyle > div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: #555;
}

.radioformikstyle > div > span {
  display: flex;
  align-items: center;
}

.fc-day {
  text-align: center;
  border: 1px solid #ddd;
  position: relative;
  /* Ensure the date cell is relatively positioned */
}

.fc-event {
  position: absolute;
  width: 95%;
  /* Position the event absolutely within the date cell */
  top: 0px;
  /* Align the event to the bottom of the date cell */
  background-color: #007bff;
  color: #fff;

  border-radius: 3px;
  text-align: center;
}

.listheadingdesign {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background: #1296b0;
  width: 100%;
  margin: 0px 0px;
  padding: 12px 10px;
}

.listheadingdesign > div {
  color: #fff;
  height: 18px;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.297px;
  /* 182.969% */
  letter-spacing: 0.2px;
}

.eventListContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 444px !important;
  overflow-y: auto;
  padding-top: 12px;
}

.eventListContainerList {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 623px !important;
  overflow-y: auto;
  padding-top: 12px;
}

.listbodydesign {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  background: #faf9f8;
  width: 100%;
  margin: 6px 0px 18px 0px;
  padding: 12px 10px;
}

.listbodydesign > div {
  color: #555;

  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.297px;
  /* 182.969% */
  letter-spacing: 0.2px;
}

.cancelcsspopup {
  padding: 0px;
}

.popupwidth {
  min-width: 765px;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror,
.fc-timegrid-more-link {
  padding: 0px;
}

.fc-timegrid-event-harness > .fc-timegrid-event {
  width: 100%;
  position: absolute;
  margin: 0px;
  padding: 2px !important;
}

.textstickbottom {
  display: flex;
  align-items: flex-end;
}

.textstickbottomheight {
  height: 50px;
}

.infinitescrollwidth {
  width: 400px;
}

.CalendarComponentListdiv {
  padding: 20px 25px 0px 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 128%;
  overflow-y: auto;
}

.meetingroomcompdispl {
  display: none;
}

.infinite-scroll-component__outerdiv {
  width: 97%;
}

.markExitHeight {
  min-height: 185px !important;
}
.webCamimgDiv {
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(231, 243, 245);
  margin-left: 4px;
  border-radius: 5px;
}
.webcampopup-open {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100%;
  top: 37px;
  display: flex;
  align-items: center;
  left: 0px;
  justify-content: center;
  z-index: 1438;
}
.webcampopup {
  contain: none !important;
}
.webcamdiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 569px;
  background-color: #ffffff;
  width: 490px;
  border-radius: 5px;
}
.webcamHeader {
  width: 100%;
  text-align: end;
  padding: 0px 6px;
}
