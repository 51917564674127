.teamMembersContainer {
  /* display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    font-size: 14px; */
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  font-size: 14px;
  overflow-y: auto;
  /* margin-top: 13px; */
}

.teamMembersImg {
  margin-top: 13px;
  width: 76%;
  aspect-ratio: 1;
  border-radius: 100%;
}

.teamMembersImgDashboard {
  /* margin: 13px; */
  max-width: 100px;
  width: 62%;
  /* max-width: 150px; */
  aspect-ratio: 1;
  border-radius: 100%;
}

.teamMembersContentHolder {
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  overflow-y: auto;
  padding-bottom: 12px;
}

.teamMembersCard {
  margin: 8px;
  /* width: 28%; */
  width: 90%;
  background: #ffffff;
  box-shadow: 0px 3px 15px rgb(0 0 0 / 6%);
  border-radius: 6px;
  background-image: url("../../../../../Assets/TeamMembersCardBackground.svg");
  background-size: 131px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: start;
  contain: content;
  align-items: center;
  text-align: center;
  padding: 13px;
  gap: 13px;
}

.subTeamContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  font-size: 14px;
  width: 90%;
}

.teamTileContentHolder {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.badgeHolderTeam {
  width: 90%;
  height: 3rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #d8f0f2;
  box-shadow: 0px 2.78226px 13.9113px rgba(0, 0, 0, 0.06);
  border-radius: 5.56452px;
  margin-top: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.teamBadgeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 9px;
}

.teambadge {
  width: 25.97px;
  height: 25.97px;
  left: 447.27px;
  top: 661.1px;
  background: #d3d2f8;
  border-radius: 16.2298px;
}

.teamMemberName {
  font-weight: 500;
  color: var(--primary);
  margin-top: 1rem;
  font-size: 16px;
}

.teamMemberRoleDashboard {
  font-size: 14px;
  font-weight: 500;
}

.teamMembersLine {
  background-color: #F7941D;
  height: 2.95px;
  width: 40px;
  margin-top: 10px;
  border-radius: 10px;
}

.teamMemberDesignation {
  font-weight: 500;
  align-self: center;
}

.teamMemberEmail {
  font-weight: 500;
}

.teamMemberStatus {
  padding: 2px 10px;
  color: var(--primary);
  background-color: var(--primary-hover);
  align-self: flex-start;
  border-radius: 4px;
  font-weight: 500;
}

.teamMembersId {
  margin-left: 15px;
}

.teamMembersNameId {
  display: grid;
  text-align: start;
}