.starredMessagesContainer {
    width: 100%;
    border-right: 1px solid #d8f0f2;
  }
  .StarredMessagesHeaderContainer {
    padding: 30px;
    display: flex;
    align-items: center;
    height: 64px;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05);
  }
  .StarredMessagesHeader svg {
    width: 20px;
    cursor: pointer;
  }
  .StarredMessagesHeader span {
    color: #1296b0;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    padding-left: 15px;
  }
  .starredMessagesMainWrapper {
    height: 100%;
    padding: 24px;
  }
  .starredMessagesMainWrapper .chatMsg {
      width: 97%;
      display: flex;
      align-items: center;
      padding: 14px 0;
      position: relative;
    }
  .starredMessagesMainWrapper .chatMsg .dateTimeBluetickContainer .dateTimeWrapper{
      display: flex;
      align-items: center;
    }
  .starredMessagesMainWrapper .chatMsg .dateTimeBluetickContainer .dateTimeWrapper label{
     padding-left: 5px;
    }
  .starredMessagesMainWrapper .chatMsg .dateTimeBluetickContainer .dateTimeWrapper div{
      padding-left: 5px;
    }
    .starredMessagesMainWrapper .allStarredMsg {
      padding-bottom: 20px;
     display: flex;
     /* align-items: center; */
    }
    .starredMessagesMainWrapper .allStarredMsg .chatMsg{
     display: flex;
     flex-direction: column;
     align-items: start;
    }
    .starredMessagesMainWrapper .allStarredMsg .chatMsg .chatMsgTextContainer,
    .starredMessagesMainWrapper .allStarredMsg .chatMsg .chatMsgImgContainer{
      width: auto;
    }
    .starredMessagesMainWrapper .allStarredMsg .chatMsg .chatMsgTextContainer svg{
      position: relative;
      right: 0px;
      bottom: 0px;
      margin-left: 15px;
    }
    .starredMessagesMainWrapper .allStarredMsg .profilePicContainer {
     width: 50px;
     margin-right: 20px;
    }
    .starredMessagesMainWrapper .allStarredMsg .profilePic {
     width: 40px;
     height: 40px;
     border-radius: 100%;
     position: relative;
     top: 15px;
     left: 0;
    }
    .starredMessagesMainWrapper .chatMsgTextContainer {
      background-color: var(--white);
      box-sizing: border-box;
      border: 0.5px solid #d8f0f2;
      border-radius: 0px 6px 6px 6px;
      color: var(--black);
      margin: 0;
      padding: 6px 12px;
      margin-bottom: 5px;
      position: relative;
    }
    .starredMessagesMainWrapper .chatMsgTextContainer .starSVG{
      position: absolute;
      right: 5px;
      bottom: 1px;
    }
    .starredMessagesMainWrapper .chatMsgImgContainer .starContainer{
      width: 100%;
      margin-top: 0 !important;
      background-color: #d8f0f2;
    }
    .starredMessagesMainWrapper .chatMsgTextContainer > label {
      font-size: 13px;
    }
    .starredMessagesMainWrapper .chatMsgSelf > .chatMsgTextContainer {
      background-color: var(--primary);
      border-radius: 6px 0px 6px 6px;
      color: var(--white);
    }
    .starredMessagesMainWrapper .chatMsgDateTime {
      position: relative;
      top: 0px;
      /* left: -195px; */
      font-weight: 400;
      font-size: 10px;
    }
    .starredMessagesMainWrapper .allStarredMsg .chatMsg .starredMsgListContainer .chatMsgImgContainer .starredImgIcon{
     margin-bottom: 8px ;
    }
    .starredMessagesMainWrapper .chatMsgImgContainer img{
      width: 200px;
      height: 100px;
      border-radius: 0px 6px 6px 6px;
    }
    .starredMessagesMainWrapper .chatMsgImgContainer{
      background-color: #d8f0f2;
      width: 200px;
      height: 100px;
      /* margin: 5px 0; */
      border-radius: 0px 6px 6px 6px;
    }
    .starredMessagesMainWrapper .allStarredMsg span{
      position: relative;
      top: 5px;
      font-weight: 400;
      font-size: 10px;
    }

    .chatMsg .chatMsgImgContainer {
      display: flex;
      flex-direction: column;
      align-items: end;
    }
    .chatMsg .starredTextContainer {
      display: flex;
      flex-direction: column;
      align-items: end;
    }
    .chatMsg .chatMsgImgContainer .starredImgIcon{
      background-color: #d8f0f2;
      display: flex;
      flex-direction: column;
      align-items: end;
      border-radius:  0px 6px 6px 6px;
    }

    .chatMsg .dateTimeBluetickContainer{
      width: inherit;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }