.overviewContainer {
  flex: 1;
  filter: drop-shadow(0px 1.8944525718688965px 10px rgba(0, 0, 0, 0.04));
  background: white;
  border-radius: 6px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.mainOverviewContainer {
  width: 100%;
  flex-grow: 1;
  overflow: auto;
  gap: 24px;
  display: flex;
  flex-direction: column;
  padding: 12px;
}
.subOverviewContainer {
  display: flex;
  gap: 24px;
  flex-shrink: 0;
}
.overviewMainContent {
  width: 100%;
  flex-grow: 1;
  padding: 24px;
}
