@import url("https://fonts.googleapis.com/css2?family=Sacramento&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");


.gifAppContainer {
  width: 100%;
}
.giphy-grid > div {
  height: 100%;
  width: 100%;
}
.gifAppHeader {
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;
}

.gifAppSearch {
  height: 40px;
  width: 100%;
  max-width: 800px;
  min-width: 100px;
  margin: 0 30px;
  border: none;
  border-radius: 5px;
  padding: 0 20px;
  border: 1px solid #1296b0;
}

.gifAppSearch:focus {
  outline: none;
}

.gifAppTitle {
  font-size: 2.5rem;
  color: #1296b0;
  font-family: "Sacramento", cursive;
  cursor: pointer;
}

@media screen and (max-width: 540px) {
  .title {
    font-size: 2rem;
  }
}

.gridContainer {
  margin: 20px;
  display: flex;
  justify-content: center;
}

.loader {
  margin-top: 40px;
  font-size: 2.5rem;
  color: #ffff;
  font-family: "Sacramento", cursive;
}
