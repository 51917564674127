.payslipDetailContainer {
  background-color: var(--form-background);
  height: 100%;
  width: calc(100% - 250px);
  z-index: 11;
  display: flex;
  flex-direction: column;
  position: absolute;
  border-radius: 8px;
  contain: content;
}
.payslipHeaderContainer {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: linear-gradient(136deg, #b3edea 0%, #ddfaf9 100%);
  filter: drop-shadow(
    0px 1.8201764822006226px 9.607928276062012px rgba(0, 0, 0, 0.04)
  );
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payslipCompanyLogo {
  height: 40px;
  flex-shrink: 0;
}
.empheadingSlip{
  font-size: 10.431px;
  color: var(--PeopleSol-Dark-Grey, #555);
  font-style: normal;
  font-weight: 500;
}
.empBodySlip{
  color: black;
  font-family: Poppins;
  font-size: 13.623px;
  font-style: normal;
  font-weight: 500;
}
.payslipCloseButton {
  position: absolute !important;
  right: 6px;
  top: 6px;
}
.payslipMainContainer {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  flex-grow: 1;
  overflow: auto;
  padding: 16px;
}
.payslipMainHeaderContainer {
  position: relative;
}
.payslipEmployeeSummaryContainer {
  margin-top: 4px;
}
.payslipEmployeeMainSummaryContainer {
  display: flex;
  flex-wrap: wrap;
}
.earningHeadSlip{
  font-size: 12.608px;
  font-style: normal;
  font-weight: 400;
}
.earningBodySlip{
  text-align: center;
font-size: 12.608px;
font-style: normal;
font-weight: 500;
}
.employeeSummaryCell {
  width: 25%;
  padding: 6px 0px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.employeeSummaryCell > .horizontalLine {
  width: 30%;
  background-color: #f7941d;
  width: 25.246px;
    height: 1.775px;
}
.paySummaryContainer {
  display: flex;
  gap: 24px;
  margin-top: 6px;
}
.paySummaryComponentContainer {
  flex-grow: 1;
  border-radius: 8px;
  box-shadow: 0px 3.4288010597229004px 17.144004821777344px 0px
    rgba(0, 0, 0, 0.06);
  contain: content;
  display: flex;
  flex-direction: column;
}
.paySummaryComponentHeader {
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e9f3f4;
  color: #1296b0;
}
.paySummaryComponentList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
}
.paySummaryComponentMain {
  border-bottom: 1px solid var(--grey);
  flex-grow: 1;
}
.paySummaryComponentFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 12px;
  font-weight: 500;
}
