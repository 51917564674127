.attendance-table {
  width: 100%;
  table-layout: fixed; /* Fix table layout to ensure equal column widths */
  border-collapse: separate;
  border-spacing: 0 6px;
  display: block;
  flex-grow: 1;
  overflow: auto;
  position: relative;
  table-layout: fixed;
}
.attendance-table tbody td {
  /* ... (your existing styles) */
  height: 50px; /* Set the height for each row */
}
.attendance-table thead th {
  /* ... (your existing styles) */
  position: sticky;
  top: 0; /* Fix the header at the top of the table container */
  background-color: #faf9f8; /* Background color for the header */
}
.attendance-table td {
  padding: 3px 6px;
  text-align: center;
  background-color: var(--table-back);
}
.attendance-table th {
  padding: 6px;
  text-align: center;
  background-color: var(--table-back);
}

.primaryBackground {
  background-color: var(--primary-hover) !important;
}
.date {
  font-size: 14px;
}
.day {
  font-size: 12px;
  color: #777;
}
.legendContainer {
  z-index: 2;
  padding: 30px 12px 24px 12px;
  display: flex;
  justify-content: space-around;
  margin-bottom: -6px;
}
.legendHolder {
  display: flex;
  gap: 9px;
  align-items: center;
}
.legendColorHolder {
  width: 15px;
  height: 15px;
  border-radius: 2px;
}
.primaryLegend {
  background-color: var(--primary);
}
.greenLegend {
  background-color: var(--green);
}
.redLegend {
  background-color: var(--notification-red);
}
.secondaryLegend {
  background-color: var(--secondary);
}
.tableScrollContainer {
  max-height: 70vh; /* Set the maximum height for the table body */
  overflow-y: auto; /* Add vertical scrollbar if content overflows */
}
.orangeLegend {
  background-color: var(--orange);
}
.pastelLegend {
  background-color: #5991c2;
}
.pinkLegend {
  background-color: var(--pink);
}
.tealLegend {
  background-color: var(--teal);
}
.dayHeader {
  white-space: nowrap;
}
.primaryHead {
  color: var(--primary);
}
.greenHead {
  color: var(--green);
}
.redHead {
  color: var(--notification-red);
}
.secondaryHead {
  color: var(--secondary);
}
.orangeHead {
  color: var(--orange);
}
.pastelHead {
  color: #5991c2;
}
.pinkHead {
  color: var(--pink);
}
.tealHead {
  color: var(--teal);
}
.dayCell {
  padding: 3px 6px;
  border-radius: 4px;
}
.primaryDayCell {
  color: var(--primary);
  border: 1px solid var(--primary);
}
.greenDayCell {
  color: var(--green);
  border: 1px solid var(--green);
}
.redDayCell {
  color: var(--notification-red);
  border: 1px solid var(--notification-red);
}
.secondaryDayCell {
  color: var(--secondary);
  border: 1px solid var(--secondary);
}
.orangeDayCell {
  color: var(--orange);
  border: 1px solid var(--orange);
}
.pastelDayCell {
  color: #5991c2;
  border: 1px solid #5991c2;
}
.pinkDayCell {
  color: var(--pink);
  border: 1px solid var(--pink);
}
.tealDayCell {
  color: var(--teal);
  border: 1px solid var(--teal);
}
.primaryDayCellFixed {
  color: white;
  background-color: var(--primary);
  border: 1px solid var(--primary);
}
.greenDayCellFixed {
  color: white;
  background-color: var(--green);
  border: 1px solid var(--green);
}
.redDayCellFixed {
  color: white;
  background-color: var(--notification-red);
  border: 1px solid var(--notification-red);
}
.secondaryDayCellFixed {
  color: white;
  background-color: var(--secondary);
  border: 1px solid var(--secondary);
}
.orangeDayCellFixed {
  color: white;
  background-color: var(--orange);
  border: 1px solid var(--orange);
}
.pastelDayCellFixed {
  color: white;
  background-color: #5991c2;
  border: 1px solid #5991c2;
}
.pinkDayCellFixed {
  color: white;
  background-color: var(--pink);
  border: 1px solid var(--pink);
}
.tealDayCellFixed {
  color: white;
  background-color: var(--teal);
  border: 1px solid var(--teal);
}
.earningHead {
  color: #82c1ad;
}
.deductionHead {
  color: #b69fb3;
}
.reimbursementHead {
  color: #a0a0cb;
}
.earningTableHead {
  background-color: #e0efeb !important;
}
.deductionTableHead {
  background-color: #fcf6fb !important;
}
.reimbursementTableHead {
  background-color: #ececf5 !important;
}
.earningCellFixed {
  color: white;
  background-color: #82c1ad;
  border: 1px solid #82c1ad;
}
.deductionCellFixed {
  color: white;
  background-color: #b69fb3;
  border: 1px solid #b69fb3;
}
.reimbursementCellFixed {
  color: white;
  background-color: #a0a0cb;
  border: 1px solid #a0a0cb;
}
.earningCell {
  color: #82c1ad;
  border: 1px solid lightgray;
}
.deductionCell {
  color: #b69fb3;
  border: 1px solid #b69fb3;
}
.reimbursementCell {
  color: #a0a0cb;
  border: 1px solid #a0a0cb;
}
