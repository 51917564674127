.moduleInsideContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.permissionTypeContainer {
  box-shadow: 0px 1.89445px 10px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border: 1px solid transparent;
}
.permissionTypeContainerActive {
  background-color: var(--primary-hover);
  color: var(--primary);
  font-weight: 500;
}
.permissionTypeContainer:hover {
  border: 1px solid #bee6ea;
}
.inlineBadge {
  background-color: var(--primary);
  color: white;
  border-radius: 10px;
  font-size: 12px;
  align-items: center;
  display: flex;
}
.inlineBadge > label {
  margin: 0 6px;
  font-weight: bold;
}
