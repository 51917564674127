.teamAttendanceItemContainer > td {
  padding: 5px;
}
.approvalFrameTabData {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.approvalActionsHolder {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.approvalActionsHolder > img {
  width: 40%;
}
.homeScreenPopup {
  background: white;
  border-radius: 5px;
  contain: content;
  width: 100%;
}
