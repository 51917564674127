.formdetailbackground {
  background: #faf9f8 !important;
}

.detailsRequest {
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
  gap: 24px;
  padding: 12px;
  background-color: white;
}

.css-o4b71y-MuiAccordionSummary-content {
  justify-content: space-between !important;
}

/* .changeForm {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: start;
  flex-wrap: "wrap";
} */

/* @media only screen and (max-width: 1500px) { */
.changeForm {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  align-items: start;
}

/* } */