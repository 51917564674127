.skipTextContainer{
    color: white;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    position: relative;
    height: 12rem;
}
.adminSidePanel{
    background: #82C1AD;
    flex-direction: column;
    contain: content;
    overflow: auto;
    height: 100%;
}
.onboardComponentContainer{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.onboardImageComp{
    height: calc(100% - 15rem);
    width: calc(100% - 4rem);
}
.sidePanelStepCount{
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #82C1AD;
    background: white;
    width: 42px;
    height: 42px;
    font-size: 21px;
    font-weight: 500;
    opacity: 0.8;
}