.socialUserProfileContainer {
  overflow: auto;
  width: 100%;
}
.socialUserProfileContainer::-webkit-scrollbar {
  display: none;
}

.socialUserProfileHeaderContainer {
  margin: 0 30px;
  padding: 10px 20px;
  border-radius: 8px 8px 0px 0px;
  background: var(--white, #fff);
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.05);
}

.socialUserProfileHeaderContainer img {
  padding: 8px 1px;
}
.socialUserProfileBodyContainer {
  margin: 0 30px;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 6%);
  padding-bottom: 20px;
  border-radius: 0 0 10px 10px;
}
.socialUserProfileBodyImgContainer {
  display: flex;
  align-items: center;
  padding: 30px;
  border-bottom: 1px solid #e6e6e6;
}
.socialUserProfileBodyImgWrapper {
  position: relative;
}
.socialAvatarBadge {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: var(--secondary);
  margin-left: 8rem;
  margin-top: 6rem;
  z-index: 2;
  padding: 0px 10px;
  border-radius: 20px;
  color: var(--white);
  font-size: 12px;
}
.socialUserProfileBodyTextWrapper {
  margin-left: 10px;
}
.socialUserProfileBodyRoleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
}
.socialUserProfileBodyRoleContainer .socialUserProfileBodyRoleWrapper .role,
.socialUserProfileBodyRoleContainer
  .socialUserProfileBodyRoleWrapper
  .designation,
.socialUserProfileBodyRoleContainer
  .socialUserProfileBodyRoleWrapper
  .department,
.socialUserProfileBodyRoleContainer
  .socialUserProfileBodyRoleWrapper
  .companyExp,
.socialUserProfileBodyRoleContainer
  .socialUserProfileBodyRoleWrapper
  .overallExp,
.socialUserProfileBodyRoleContainer
  .socialUserProfileBodyRoleWrapper
  .location {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.socialUserProfileBodyRoleContainer .socialUserProfileBodyRoleWrapper img {
  width: 50px;
}
.socialUserProfileBodyRoleContainer .socialUserProfileBodyRoleWrapper p {
  color: #555;
  font-weight: 500;
}
.socialUserProfileBodyRoleContainer .socialUserProfileBodyRoleWrapper span {
  color: #f7941d;
  font-weight: 500;
}
.socialUserProfileBodyAboutContainer {
  padding: 30px;
  border-bottom: 1px solid #e6e6e6;
}
.socialUserProfileBodyBadgeWrapper {
  display: flex;
  align-items: center;
  border-radius: 13.5px;
  background: #fff;
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.06);
  padding: 4px 10px;
  margin-left: 20px;
}
.socialUserProfileBodyBadgeWrapper p {
  font-size: 12px;
  padding-left: 5px;
  color: #1296b0;
  font-weight: 500;
}
.socialUserProfileBodyRoleWrapper h6 {
  height: 30px;
  width: 1px;
  background: #e6e6e6;
  margin: 0 8px;
}
.socialUserProfileBodyAboutContainer h5 {
  color: #1296b0;
  font-weight: 500;
  margin-bottom: 3px;
}
.socialUserProfileBodyAboutContainer p {
  color: var(--PeopleSol-Dark-Grey, #555);
  text-align: justify;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.userProfileHomeEngagePostContainer {
  width: 100%;
  border-radius: 8px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  contain: content;
  padding: 0 1px;
}
.userProfileAllPostContainer {
  border-radius: 15px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.06);
  margin: 5px 30px 20px 30px;
}
.socialUserProfileBodyActivityContainer .socialUserProfileBodyActivityWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 25px;
  margin: 20px 30px;
  border-radius: 10px;
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.06);
}
.socialUserProfileBodyActivityWrapper p {
  font-weight: 500;
  color: #1296b0;
  font-size: 16px;
}
.socialUserProfileBodyActivityWrapper button {
  font-weight: 500;
  color: #1296b0;
  background-color: #d8f0f2;
  margin: 0;
}
.socialUserProfileBodyActivityWrapper
  .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  border-radius: 4px;
  padding: 0;
}
.activityListBottomContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  color: #bfbfbf;
}
.activityListBottomContainer p {
  font-size: 12px;
}
.activityList {
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 20px;
}
.activityList p {
  font-size: 13px;
  color: #bfbfbf;
  margin-bottom: 7px;
}
.activityListBottomWrapper {
  display: flex;
  align-items: center;
}
.activityListBottomWrapper .activityListDislikeContainer span {
  position: relative;
  bottom: 4px;
}
.activityListBottomWrapper .activityListLikeContainer span {
  position: relative;
  bottom: 2px;
}
.activityListBottomWrapper .activityListLikeContainer {
  margin-right: 30px;
}
