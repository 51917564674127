.payrollSummaryContainer {
  border: 1px solid rgb(240, 240, 255);
  border-radius: 8px;
  width: calc(50% - 6px);
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.tdsSummary {
  color: #8A88ED;
  border-color: #F0F0FF;
  /* background-color: var(--purple-hover); */
}

.ptSummary {
  color: var(--Payroll, #da5db6);
  background-color: #FFEEFA;

}

.tdsSummary>svg>path {
  stroke: var(--purple);
}

.pt>svg>path {
  stroke: var(--Payroll, #da5db6)
}

.overH {
  overflow: hidden;
}

.esicSummary {
  color: #77A2C7;
  border-color: #EAF5FF;
  /* background-color: var(--moonstone-hover); */
}

.esicSummary>svg>path {
  stroke: var(--moonstone);
}

.insuranceSummary {
  color: #DA5DB6;
  border-color: #FFEEFA;
  /* background-color: var(--pink-hover); */
}

.insuranceSummary>svg>path {
  stroke: var(--pink);
}

.epfSummary {
  color: #5BC4BF;
  border-color: #D4F2F1;
  /* background-color: var(--teal-hover); */
}

.epfSummary>svg>path {
  stroke: var(--teal);
}

.gratuitySummary {
  color: #F7941D;
  border-color: #FFF4E5;
  /* background-color: var(--secondary-hover); */
}

.gratuitySummary>svg>path {
  stroke: var(--secondary);
}

.loanSummary {
  color: #A0A0CB;
  border-color: #ECECF5;
  /* background-color: var(--blue-hover); */
}

.loanSummary>svg>path {
  stroke: var(--blue);
}