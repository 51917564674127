.hierarcy-container {
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: inherit;
  margin: auto;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  width: 100%;
}

.hierarcy-container::after {
  background-color: #bfbfbf;
  content: "";
  margin: 18px;
  height: fit-content;
  position: absolute;
  display: flex;
  align-items: center;
  left: 44%;
  justify-content: center;
  width: 2px;
  height: 78%;
  border-radius: 9.76757px;
}

.stepsH {
  text-align: center;
  padding: 14px;
  font-size: 12px;
  padding-top: 20px;
  margin-right: -5px;
  width: 195px;
  height: 70px;
  margin: 10px 0;
  max-width: 194px;
  font-weight: 500;
  z-index: 1;
  border: 0.945915px solid #d8f0f2;
  box-shadow: 0px 1.79199px 3.78366px rgb(0 0 0 / 2%);
  background: #ffffff;
  border: 1px solid #f1f2f2;
  border-radius: 8px;

}

.hierarcy-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 6px 13px 0px 11px;
  width: 100%;
  height: 96%;
}