.knowledgeBackground {
  background-image: url(../../../../../Assets/KnowledgeBackground.svg);
  background-repeat: no-repeat;
  margin-top: 0;
}

.knowledgeMainContainer {
  display: flex;
  /* border: 1px solid black; */
  height: 95%;
  width: 95%;
}

.knowledgeLeft {
  height: 100%;
  width: 355px;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
}

.knowledgeRight {
  height: 100%;
  /* width: 100%; */
  display: flex;
  flex: 1 1;
  align-items: flex-start;
}

.knowledgeTitleCss {
  display: flex;
  justify-content: space-between;
  color: var(--PeopleSOl-Primery, #1296b0);
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 133.333% */
}

.CarouselCover .carousel img {
  width: 100% !important;
  height: auto !important;
  object-fit: contain !important;
  /* Ensure the image is fully visible */
}

.CarouselCover .carousel img {
  width: 100% !important;
  height: auto !important;
  object-fit: contain !important;
  /* Ensure the image is fully visible */
}

.CarouselCover {
  /* height: 97%; */
  height: 41vw;
  max-width: 90%;
  width: auto;
  object-fit: contain !important;
  padding: 2px 0px 0px 74px;
}
.CarouselCover > .carousel-root .selected {
  background-color: white;
}

@media (min-width: 1600px) and (max-width: 1800px) {
  .CarouselCover {
    /* height: 84%; */
    height: 41vw;
    max-width: 95%;
    width: auto;
    object-fit: contain !important;
    padding: 2px 0px 0px 74px;
  }
}

@media (max-width: 1600px) {
  .CarouselCover {
    height: 41vw;
    max-width: 95%;
    width: auto;
    object-fit: contain !important;
    padding: 2px 0px 0px 74px;
  }
}

.knowledgeSubTitleCss {
  color: var(--PeopleSol-Dark-Grey, var(--black, #555));
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  cursor: pointer;
  padding: 0px 11px;
  border-radius: 7px;
}

.knowledgeSubTitleCssActive {
  color: var(--PeopleSol-Dark-Grey, var(--black, #555));
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  cursor: pointer;
  padding: 0px 11px;
  border-radius: 7px;
  background: var(--Pastel, #d8f0f2);
}

.knowledgeSubTitleCover {
  padding: 0px 10px;
}

.knowledgeTop {
  height: 25%;
}

.knowledgeBottom {
  height: 95%;
  overflow: auto;
  contain: content;
  background: linear-gradient(
    161deg,
    rgba(18, 150, 176, 0.07) -0.14%,
    rgba(18, 150, 176, 0.06) 101%
  );
  padding: 24px;
  border-radius: 14px;
}

.knowledgeContentholder {
  display: flex;
  flex-direction: column;
  padding: 33px;
  line-height: 31px;
}

.knowledgeContentholderNew {
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  border-bottom: 1px dotted #555;
}

.knowledgeContentholderNewend {
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  /* border-bottom: 1px dotted #555; */
}

.stepsContainer {
  display: flex;
  flex-direction: column;
  line-height: 32px;
  padding-bottom: 10px;
}

.loginContainer {
  display: flex;
  flex-direction: column;
  padding: 33px;
  padding-left: 60px;
}

.stepsContainer > label {
  color: black;
  width: 75%;
}

.verticalGrayLine {
  width: 2px;
  background-color: var(--grey);
  display: flex;
  height: 100%;
  /* margin-top: auto; */
}

.selectedClass {
  color: var(--primary);
}
