.rewardsContainer {
    width: 90%;
    margin: 1rem;
    align-self: center;
    justify-self: center;
    height: 350px;
    background: #FFFFFF;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    background-image: url("../../../Assets/RewardsBackground.svg");
    background-repeat: no-repeat;
    display: flex;
}
.rewardsProfileInfoContainer{
    width: 350px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.rewardsProfileImage{
    width: 50%;
    height: 50%;
    border-radius: 100%;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.05);
    border: 5px solid white;
    contain: content;
}
.rewardsProfileImage > img{
    width: 100%;
    height: 100%;
}
.rewardsBadgesContainer{
    flex-grow: 1;
    height: 100%;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.rewardLine{
    background-color: var(--grey);
    height: 1px;
    width: 95%;
    margin-top: 0.5rem;
    border-radius: 10px;
}
.rewardIconHolder{
    width: 95%;
    margin-top: 2rem;
    height: 50%;
    display: flex;
}
.rewardIcon{
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.rewardIcon > img{
    width: 60%;
    height: 60%;
}
.rewardName{
    font-weight: 500;
    color: var(--primary);
    font-size: 12px;
    text-align: center;
    margin-top: 1.5rem;
}
.rewardDate{
    font-size: 10px;
    margin-top: 0.5rem;
    text-align: center;
}