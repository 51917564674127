.socialEngageContainer {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.06);
  margin-top: 24px;
  display: flex;
  justify-content: center;
  height: calc(100% - 55px);
  padding: 2% 5%;
  padding-top: 25px;
}
.socialEngageAside {
  width: 18%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}
.socialEngageAsideMenuContainer {
  width: 100%;
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 6%);
  border-radius: 8px;
  contain: content;
  max-height: calc(100% - 24px);
  overflow-y: auto;
}
.socialMenuOptions {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  contain: content;
  padding: 16px 6px 16px 0;
  text-decoration: none;
}
.socialMenuOptions > label:hover {
  cursor: pointer !important;
}
.socialMenuOptions:hover {
  cursor: pointer;
  background-color: var(--global-hover);
}
.socialMenuOptions > .socialVerticalLine {
  left: 0;
  height: 100%;
  width: 5px;
  background-color: transparent;
  position: absolute;
  border-radius: 0;
}

.socialMenuOptionsImg {
  width: 60px;
  text-align: center;
}
.socialMenuOptions label {
  color: var(--primary);
  font-weight: 500;
}
.socialMenuOptions.active > .socialVerticalLine {
  background-color: var(--primary);
}
.socialMenuOptions.active {
  background-color: var(--primary-hover);
  color: var(--primary);
}
.socialEngageMainContainer {
  width: 54.5%;
  display: flex;
}
.communityHomeEngageContainer {
  overflow: auto;
  width: 27%;
}
.communityHomeEngageContainer::-webkit-scrollbar {
  display: none;
}
.NoticountCss {
  background-color: red;
  color: white;
  border-radius: 50%;
  font-size: 12px;
  min-width: 20px;
  margin-left: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  position: absolute;
  right: 3px;
}
