.timelineContainer{
    width: 90%;
    margin: 1rem;
    align-self: center;
    justify-self: center;
    background: #FFFFFF;
    box-shadow: 0px 3px 15px rgb(0 0 0 / 6%);
    border-radius: 6px;
    background-image: url("../../../Assets/RewardsBackground.svg");
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.timelineTitle{
    font-weight: 500;
    color: var(--primary);
    font-size: 22px;
    align-self: flex-start;
    margin-top: 2.5rem;
    margin-left: 2rem;
}
.timeLineHolder{
    width: 80%;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;
    gap: 4rem;
}
.timelineHeaderContainer{
    background: #FFFFFF;
    box-shadow: 0px 3.56872px 17.8436px rgba(0, 0, 0, 0.06);
    border-radius: 16.0592px;
    display: flex;
    align-items: center;
    position: relative;
}
.rewardImgContaner{
    background-color: #FFFFFF;
    border-radius: 500%;
    margin: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0px 2.62537px 5.25075px rgba(0, 0, 0, 0.1));
    z-index: 1;
}
.rewardImgContaner > img{
    width: 1.5rem;
    height: 1.5rem;
    margin: 0.5rem;
}
.orange{
    background-color: var(--secondary-hover);
}
.timelineHeaderContainer > label{
    color: var(--secondary);
    font-weight: 500;
    margin-right: 1rem;
    font-size: 1.2rem;
    width: 15rem;
}
.square{
    border-radius: 20%;
}
.timelineDottedLine{
    position: absolute;
    height: 4rem;
    width: 1rem;
    left: 1.7rem;
    bottom: -4rem;
}
.timelineSubHeaderContainer{
    display: flex;
    align-items: center;
    position: relative;
    align-self: center;
    margin-left: -25rem;
    margin-top: -1rem;
}
.timelineSubHeaderContainer > label{
    width: 10rem;
}
.timelineSubDottedLine{
    position: absolute;
    height: 4rem;
    width: 1rem;
    left: 11.8rem;
    bottom: -3rem;
}