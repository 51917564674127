.timeline-container {
  flex: 3 1;
  display: flex;
  flex-direction: column;
  position: relative;
  height: fit-content;
  font-family: inherit;
  margin: 20px 0;
  padding: 0 44px;
}

.reqD {
  overflow: auto;
  background: white;
  padding: 0 24px;
}

.timeline-container::before {
  background-color: #d8f0f2;
  content: "";
  position: absolute;
  left: 16px;
  width: 1px;
  height: 90%;
  margin: 31px 0;
}

.timeline-item {
  margin: 10px 0;
  width: 100%;
  background: #ffffff;
}

.detailsSep {
  overflow: auto;
  margin: 27px 0 0 0px;
  height: 91%;
  padding: 10px;
  background-color: var(--white);
  overflow: auto;
  display: grid;
  margin: 24px;
  gap: 21px;
  padding: 10px;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 2%);
}

.timeline-item-content {
  margin-top: 13px;
  border-radius: 8px;
  padding: 2px 12px 0px 11px;
  width: 100%;
  line-height: 15px;
  flex-direction: column;
  min-height: 85px;
  justify-content: center;
  display: flex;
}

.timeline-item-content .tag {
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  top: 0;
  right: 0;
  letter-spacing: 1px;
  padding: 5px;
  position: absolute;
  text-transform: uppercase;
}

.timeline-item-content time {
  position: absolute;
  right: 15px;
  bottom: 10px;
  font-size: 13px;
}

.timeline-item-content p {
  margin: 4px 0;
}

.circmain {
  position: absolute;
  top: 52%;
  left: 93%;
  border: 1.5px solid #ffffff;
  border-radius: 50%;
  background: red;
  color: white;
  width: 22px;
  height: 22px;
  font-size: 11px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.01em;
}

.textP {
  font-size: 13px;
  max-width: 92px;
  min-width: 67.34px;
  border-radius: 4.5759px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px 0px 3px 0px;
  padding: 2px 4px;
  position: relative;
  bottom: 0;
}

.timeline-item-content a {
  font-size: 14px;
  font-weight: bold;
}

.timeline-item-content a::before {
  content: " ►";
  font-size: 12px;
}

.timeline-item-content .circle {
  border-radius: 50%;
  position: absolute;
  left: 0;
  width: 32px;
  height: 32px;
  display: flex;
  text-align: center;
  color: white;
  align-items: center;
  justify-content: center;
}

.comentCont {
  position: relative;
  margin-top: 35px;
  padding-left: 25px;
  flex: 3;
  width: 100%;
  height: fit-content;
  background: #f4fbfc;
  border-radius: 8px;
}

.comentInner {
  box-sizing: border-box;
  width: 90%;
  height: 165px;
  padding-left: 5px;
  background: #ffffff;
  border: 1px solid #d8f0f2;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 2%);
  border-radius: 4px;
}

.labelCom {
  padding: 23px 0px 11px 26px;
  font-weight: 500;
  font-size: 19px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #1296b0;
}

.btnTime {
  background: #ffffff;
  color: #1296b0;
  border: 1px solid #1296b0;
  margin: 12px;
  padding: 2px 19px;
  outline: 0;
  border-radius: 3px;
  font-size: 15px;
}

.btnTime:hover {
  cursor: pointer;
}

.cancelbtn {
  background: #ffffff;
  color: #1296b0;
  border: 1px solid #1296b0;
  margin: 12px;
  padding: 5px 21px;
  outline: 0;
  border-radius: 3px;
  font-size: 15px;
}

.cancelbtn:hover {
  cursor: pointer;
}

.createBtnTime {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  bottom: -1px;
  width: 100%;
  height: 65px;
  align-items: center;
  padding-right: 33px;
  background-color: transparent;
}

.tableNoti {
  border-radius: 4px;
  width: 100%;
}

.tableNoti>thead>tr>td {
  padding: 3px 12px;
}

.tableNoti>tbody>tr>td {
  padding: 3px 12px;
}

.headNoti {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  align-items: center;
  color: var(--primary);
}

table {
  border-collapse: collapse;
}

.trStyle {
  border-top: 0.75px solid #e6e6e6;
  height: 50px;
  font-size: 13px;
}

.trStyle:first-child {
  border-top: 1px solid #1296b0 !important;
}

.butn {
  margin: 12px;
  padding: 6px 19px;
  outline: 0;
  border: 0;
  border-radius: 3px;
  font-size: 15px;
}

.lowdiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
}

.circmains {
  position: absolute;
  border: 1px solid #ffffff;
  border-radius: 50%;
  color: white;
  width: 10px;
  right: -2px;
  top: -3px;
  height: 10px;
  font-size: 8px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.01em;
}

.detatime {
  background: #f4fbfc;
  border-radius: 8px;
  display: flex;
  width: 100%;
}

.detailsEReq {
  overflow: auto;
  display: grid;
  margin: 24px;
  gap: 21px;
  padding: 10px;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 2%);
  border-radius: 4px;
  background-color: var(--white);
}

.sendcourierstyle>div>label {
  font-weight: 400 !important;
  font-size: 15px !important;
  /* padding: 10px 13px !important; */
  color: #555555 !important;
  display: block !important;
  font-family: Poppins !important;
}

.detailInner {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 12px;
  /* margin-top: -20px; */
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.createFootDetails {
  display: flex;
  justify-content: flex-end;
  background-color: white;
  position: sticky;
  bottom: -10px;
  margin: -10px;
  border-top: 1px solid rgba(230, 230, 230, 1);
}

.wrapText {
  word-wrap: break-word;
}

.trStylePaddidng {
  padding: 0px !important;
}