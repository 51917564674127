.notifications-page {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: var(--primary-hover);
  mix-blend-mode: normal;
  backdrop-filter: blur(10px);
  position: relative;
  gap: 0;
  overflow: auto;
}

.notifications-Background {
  width: 100%;
}

.notificationsMainContainer {
  background-color: white;
  position: absolute;
  right: 0px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  width: 40%;
  align-items: center;
  justify-content: center;
}

.notifications-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--primary);
  border-bottom: 1px solid var(--primary);
  width: calc(100% - 24px);
  margin: 18px 12px 0 12px;
  font-size: 20px;
  padding-bottom: 6px;
  color: var(--primary);
  font-weight: 500;
}

.notifications-content {
  overflow-y: auto;
  flex-grow: 1;
  overflow: hidden;
  padding: 12px;
  width: 100%;
}

.notificationClearIcon {
  /* position: absolute;
    right: 0; */
  display: flex;
  justify-content: end;
}
.notificationCount {
  font-size: 16px;
  font-weight: 500;
}
