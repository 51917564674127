.requestConfigContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0dcdc;
    padding-bottom: 8px;
}

.requestConfigHeading {
    color: #555;
    font-family: Poppins;
    font-weight: 500;
    font-size: 17px;
}

.requestConfigBoxContainer {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    gap: 37px 47px;
    padding: 25px 0px;
}

.requestConfigBox {
    min-width: 376px;
    min-height: 137px;
    width: 31%;
    border-radius: 8px;
    border-width: 1px;
    border: 1px solid #E6E6E6;
    padding-top: 12px;
    padding-right: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
}

.requestConfigBoxHeading {
    font-family: Poppins;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0%;
    color: #1296B0;
    margin-bottom: 2px;
}

.requestConfigBoxDesc {
    font-family: Poppins;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0%;
    color: #555555;
}