.resetPwdContainer{
    display: flex;
    align-items: center;
    padding: 3% 5%;

}
.resetPwdContainer .resetPwdImgContainer{
    width: 45%;
    margin-left: 20px;
}

.resetPwdContainer .resetPwdFormContainer{
    width: 55%;
}
.resetPwdPolicy{
    margin-top: 25px;
}
.resetPwdPolicy h2{
    color: #1296B0;
    font-size: 15px;
    margin-bottom: 5px;
}
.resetPwdPolicy p{
    font-size: 13px;
}
.selectEmpForResetPwd{
    padding: 2px 0px !important;
}