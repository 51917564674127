.newChatContainer .newChatListContentContainer,
.MuiAutocomplete-listbox .newChatListContentContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}
.newChatContainer .newChatListContentContainer .newChatActiveImage,
.MuiAutocomplete-listbox .newChatListContentContainer .newChatActiveImage {
  height: 45px;
  width: 45px;
  border-radius: 100%;
  margin: 12px;
  position: relative;
}
.newChatContainer .newChatListContentContainer .newChatActiveImage > img,
.MuiAutocomplete-listbox
  .newChatListContentContainer
  .newChatActiveImage
  > img {
  height: 100%;
  width: 100%;
  border-radius: 100%;
}
.newChatContainer .newChatListContentContainer .newChatPostMainTextContainer,
.MuiAutocomplete-listbox
  .newChatListContentContainer
  .newChatPostMainTextContainer {
  display: flex;
  align-items: start;
  flex-direction: column;
}
.newChatContainer
  .newChatListContentContainer
  .newChatPostMainTextContainer
  .chatListName,
.MuiAutocomplete-listbox
  .newChatListContentContainer
  .newChatPostMainTextContainer
  .chatListName {
  font-size: 15px !important;
  color: black;
  font-weight: 500;
}
.newChatContainer
  .newChatListContentContainer
  .newChatPostMainTextContainer
  .chatListRole,
.MuiAutocomplete-listbox
  .newChatListContentContainer
  .newChatPostMainTextContainer
  .chatListRole {
  font-size: 13px;
  color: rgb(114, 111, 111);
  font-weight: 500;
}
.newChatContainer .newChatListContentContainer:active,
.MuiAutocomplete-listbox .newChatListContentContainer:active {
  background-color: rgba(216, 240, 242, 0.4);
}
.newChatContainer .newChatListContentContainer:active .chatListName,
.MuiAutocomplete-listbox .newChatListContentContainer:active .chatListName {
  color: var(--primary);
}
.newChatSearchContainer {
  position: relative;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newChatSearchContainer .chatListSearch {
  box-sizing: border-box;
  background: #eff9fa;
  border: 0.5px solid #d8f0f2;
  border-radius: 4.65352px;
  margin: 12px 0;
  width: 93%;
  padding: 6px 12px;
  outline: none;
}

.newChatSearchContainer svg {
  position: relative;
  right: 20px;
}

.newScrollbar::-webkit-scrollbar {
}

.newScrollbar::-webkit-scrollbar-button:single-button {
  display: block;
  background-size: 7px;
}
.newScrollbar::-webkit-scrollbar-button:single-button:increment {
  height: 12px;
  width: 16px;
  background-repeat: no-repeat;
  background-position: center 4px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(231, 231, 231)'><polygon points='0,0 100,0 50,50'/></svg>");
}
.newScrollbar::-webkit-scrollbar-button:single-button:decrement {
  height: 12px;
  width: 16px;
  background-repeat: no-repeat;
  background-position: center 4px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(231, 231, 231)'><polygon points='50,00 0,50 100,50'/></svg>");
}

.newScrollbar::-webkit-scrollbar {
  width: 13px;
}

.newScrollbar::-webkit-scrollbar-track {
  background: #fff;
}

.newScrollbar::-webkit-scrollbar-thumb {
  border: 5px solid #fff;
  background-color: #e7e7e7;
  border-radius: 15px;
}
.newChatActiveImage > img,
.newChatActiveImage .activeImageContainer {
  height: 100%;
  width: 100%;
  border-radius: 100%;
}
.newChatActiveImage .activeImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
