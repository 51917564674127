.dataTableRow {
  display: flex;
  gap: 12px;
  align-items: center;
}
.dataTableRowCell:first-child {
  width: 250px;
}
.dataTableRowCell:nth-child(2) {
  width: 50px;
}
.dataTableRowCell:nth-child(3) {
  color: var(--primary);
  font-weight: 500;
}
.dataTableRowContainer {
  margin: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.dataTableRowCell {
  display: flex;
  flex-direction: column;
}
.disableContainer {
  margin: 24px;
  display: flex;
  gap: 6px;
  align-items: center;
  width: fit-content;
  padding: 6px;
  border-radius: 6px;
}
.disableContainer:hover {
  color: var(--primary);
  background-color: var(--global-hover);
}
.editActiveBackground {
  background-color: var(--form-background);
}
.desc {
  margin-left: 45px;
  display: block;
  margin-top: -20px;
  font-size: 11px;
}
