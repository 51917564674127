.boxViewContainer {
  margin-top: 6px;
  height: calc(100% - 60px);
  overflow: auto;
}
.jobDetailContainerHolder > .jobDetailContainer:first-child > .verticalLine {
  background-color: var(--orange) !important;
}
.jobDetailContainerHolder > .jobDetailContainer:nth-child(2) > .verticalLine {
  background-color: var(--pink) !important;
}
.jobDetailContainerHolder > .jobDetailContainer:nth-child(3) > .verticalLine {
  background-color: var(--purple) !important;
}
.jobDetailContainerHolder > .jobDetailContainer:nth-child(4) > .verticalLine {
  background-color: var(--teal) !important;
}
.payrollBoxView {
  overflow: unset !important;
  height: fit-content !important;
}
.grC{
  background-color: rgb(13, 181, 60) !important;
  color: white;
  font-size: 12px;
}