.goalsActionsHolder {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

/* .goalsActionsHolder > img{
    width: 40%;
} */

.goalsText {
  font-size: 13px;
  padding: 2px 4px;
  color: #f7941d;
  background: #fdead2;
  border-radius: 4px;
  width: 50%;
  display: flex;
  justify-content: center;
}
