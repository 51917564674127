.resignationInnerContainer{
    width: 100% !important;
    height: calc(100% - 51px) !important;
    border-radius: 0 !important;
    display: flex;
}
.resignationSidePanel{
    width: 40%;
    background: #5BC4BF;
    position: relative;
    display: flex;
    flex-direction: column;
}
.resignationMainContainer{
    padding: 5% 4%;
    align-items: center;
    display: flex;
    width: 60%;
    flex-direction: column;
    background-color: var(--form-background);
}
