.attachActivityContainer {
  /* position: absolute;
    top: 0;
    right: 0;
    z-index: 10; */
}

.attachActivityHolder {
  border: none;
  border-radius: 6px;
  position: absolute;
  background-color: white;
  bottom: 64px;
  left: 32%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.attachActivityHolder {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: fit-content;
}

.headingFilterHeader {
  border: none;
}

.attachActivityHolder .headingFilterHeader h5 {
  width: 111px;
  font-size: 13px;
  color: rgb(95, 95, 95);
}

.attachActivityHolder .headingFilterHeader {
  cursor: pointer;
}

.attachActivityHolder .headingFilterHeader:hover {
  background-color: rgba(216, 240, 242, 0.4);
}

.deleteMsgConfirmAlert .react-confirm-alert-button-group button:nth-child(1) {
  background: none;
  color: red;
  font-size: 1rem;
  font-weight: 600;
  margin-right: 10px;
  padding: 8px 35px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.deleteMsgConfirmAlert .react-confirm-alert-button-group button:nth-child(2) {
  font-size: 16px;
  font-weight: 400;
  padding: 8px 10px;
  color: #fff;
  background-color: #1296b0;
}

.deleteMsgConfirmAlert .react-confirm-alert-button-group button:nth-child(3) {
  font-size: 16px;
  font-weight: 400;
  padding: 5px 5px;
  color: #fff;
  background-color: #1296b0;
}