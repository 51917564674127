.side-navigation {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  mix-blend-mode: normal;
  backdrop-filter: blur(10px);
  position: relative;
  gap: 0;
  overflow: hidden;
}

/* .labelHide{
  size: 1px;
  transition: 0.5s;
} */
.mainMenuMainContainer {
  height: 100%;
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  background-color: white;
  display: flex;
  overflow: auto;
}

.mainMenuContainer {
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  width: 100%;
}

.mainMenuTileContainer {
  width: 100%;
  height: 4rem;
  display: flex;
  flex-shrink: 1;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  
}
.mainMenuTileContainer::-webkit-scrollbar {
  width: 3px;
  scroll-behavior: smooth
}
.mainMenuTileContainer {
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) rgba(0, 0, 0, 0.1);
}


.mainMenuIconContainer {
  width: 100%;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 0.8rem;
  justify-content: space-around;
  margin-right: 10px;
}

.mainMenuTile {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0;
  position: relative;
}

.sideLabel {
  font-size: 13px;
}

.mainMenuVerticalBar {
  height: 100%;
  width: 8px;
}

.mainMenuTileIcon {
  width: 1.8rem;
  height: 1.8rem;
}

.mainMenuEndContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  overflow-y: hidden;
  overflow-x: hidden;
  height: 98px;
  position: relative;
}
.mainMenuEndContainer > .mainMenuHorizontalLine {
  position: absolute;
  top: 0;
}
.mainMenuHorizontalLine {
  width: 100%;
  height: 2px;
  background-color: var(--grey);
}

.mainMenuEndTileIcon {
  width: 100%;
  height: 1.5rem;
  margin-top: 0.5rem;
  /* margin-bottom: 0.5rem; */
}

.mainMenuEndTile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 6px;
}

.settingImg {
  transition: 0.6s;
  transform: rotate(-45deg);
}

.mainMenuEndTile:hover > .settingImg {
  transition: 0.6s;
  transform: rotate(45deg);
}

.logoutArrow {
  transition: 0.6s;
  transform: translateX(-3%);
}

.mainMenuEndTile:hover > svg > .logoutArrow {
  transition: 0.6s;
  transform: translateX(14%);
}

.endMenuLabel {
  /* display: none; */
  color: var(--primary-text);
}

.mainMenuEndTile:hover > .endMenuLabel {
  display: block;
  color: var(--primary-text);
}

.firstMenu {
  position: absolute;
  margin: 0;
  display: flex;
  flex-direction: row;
  top: 0;
  left: 80px;
  background-color: white;
  height: 100%;
  width: 0;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
}

.masterContainer {
  height: 98px;
  width: 100%;
  bottom: 0;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
}

.masterContainer > button {
  margin: 44px 76px;
}

.secondMenu {
  position: absolute;
  margin: 0;
  display: flex;
  flex-direction: row;
  top: 0;
  left: calc(80px + 240px);
  overflow: auto;
  height: 100%;
  width: 0;
  transition: all 0.1s ease-in-out;
  background-color: white;
  overflow-x: hidden;
}
.premSv{
  position: absolute;
  top: -9px;
  right: 6px;
}
.firstMenuTileContainer {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.firstMenuTileMasterContainer {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  width: 100%;
}

.verticalLine {
  height: 100%;
  color: var(--grey);
}

.singlePixel {
  width: 1px;
  z-index: 1;
}

.firstMenuTile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  position: relative;
  color: var(--primary-text);
}

.badgeCenter {
  top: 33%;
  right: 15%;
}

.badgeCenterSecond {
  top: 33%;
  right: 5%;
}

.firstMenuTile:hover {
  cursor: pointer;
}

.firstMenuTile:hover > label {
  cursor: pointer;
}

.home {
  color: var(--primary);
}

.homeTileActive > .mainMenuVerticalBar {
  background-color: var(--primary);
}

.homeActive {
  width: 240px;
  transition: all 0.2s ease-in-out;
}

.homeSubtile:hover {
  color: var(--primary);
}

.homeTileActive {
  background-color: var(--primary-hover);
  color: var(--primary);
}

.homeSubmenuActive {
  width: 13.5rem;
  transition: all 0.1s ease-in-out;
}
.global {
  color: var(--primary-text);
}
.hrms {
  color: var(--green);
}

.hrmsTileActive > .mainMenuVerticalBar {
  background-color: var(--green);
}

.hrmsActive {
  width: 240px;
  transition: all 0.2s ease-in-out;
}

.hrmsSubtile:hover {
  color: var(--green);
}

.hrmsTileActive {
  background-color: var(--green-hover);
  color: var(--green);
}

.hrmsSubmenuActive {
  width: 13.5rem;
  transition: all 0.1s ease-in-out;
}

.payroll {
  color: var(--pink);
}

.payrollTileActive > .mainMenuVerticalBar {
  background-color: var(--pink);
}

.payrollActive {
  width: 240px;
  transition: all 0.2s ease-in-out;
}

.payrollSubtile:hover {
  color: var(--pink);
}

.payrollTileActive {
  background-color: var(--pink-hover);
  color: var(--pink);
}

.payrollSubmenuActive {
  width: 13.5rem;
  transition: all 0.1s ease-in-out;
}

.ats {
  color: var(--blue);
}

.atsTileActive > .mainMenuVerticalBar {
  background-color: var(--blue);
}

.atsActive {
  width: 240px;
  transition: all 0.2s ease-in-out;
}

.atsSubtile:hover {
  color: var(--blue);
}

.atsTileActive {
  background-color: var(--blue-hover);
  color: var(--blue);
}

.atsSubmenuActive {
  width: 13.5rem;
  transition: all 0.1s ease-in-out;
}

.pms {
  color: var(--orange);
}

.pmsTileActive {
  background-color: var(--orange-hover);
}

.pmsTileActive > .mainMenuVerticalBar {
  background-color: var(--orange);
}

.pmsActive {
  width: 240px;
  transition: all 0.2s ease-in-out;
}

.pmsSubtile:hover {
  color: var(--orange);
}

.pmsSubmenuActive {
  width: 13.5rem;
  transition: all 0.1s ease-in-out;
}

.ld {
  color: var(--teal);
}

.ldTileActive > .mainMenuVerticalBar {
  background-color: var(--teal);
}

.ldActive {
  width: 240px;
  transition: all 0.2s ease-in-out;
}

.ldSubtile:hover {
  color: var(--teal);
}

.ldTileActive {
  background-color: var(--teal-hover);
  color: var(--teal);
}

.ldSubmenuActive {
  width: 13.5rem;
  transition: all 0.1s ease-in-out;
}

.admin {
  color: var(--magenta);
}

.adminTileActive > .mainMenuVerticalBar {
  background-color: var(--magenta);
}

.adminActive {
  width: 240px;
  transition: all 0.2s ease-in-out;
}

.adminSubtile:hover {
  color: var(--magenta);
}

.adminTileActive {
  background-color: var(--magenta-hover);
  color: var(--magenta);
}

.adminSubmenuActive {
  width: 13.5rem;
  transition: all 0.1s ease-in-out;
}

.itsm {
  color: var(--moonstone);
}

.itsmTileActive > .mainMenuVerticalBar {
  background-color: var(--moonstone);
}

.itsmActive {
  width: 240px;
  transition: all 0.2s ease-in-out;
}

.itsmSubtile:hover {
  color: var(--moonstone);
}

.itsmTileActive {
  background-color: var(--moonstone-hover);
  color: var(--moonstone);
}

.itsmSubmenuActive {
  width: 13.5rem;
  transition: all 0.1s ease-in-out;
}

.travel {
  color: var(--secondary);
}
.reports {
  color: #b443c6;
}

.travelTileActive > .mainMenuVerticalBar {
  background-color: var(--secondary);
}
.reportsTileActive > .mainMenuVerticalBar {
  background-color: #b443c6;
}

.travelActive {
  width: 240px;
  transition: all 0.2s ease-in-out;
}
.reportsActive {
  width: 240px;
  transition: all 0.2s ease-in-out;
}

.travelSubtile:hover {
  color: var(--secondary);
}
.reportskpiSubtile:hover {
  color: #b443c6;
}

.travelTileActive {
  background-color: var(--secondary-hover);
  color: var(--secondary);
}
.reportsTileActive {
  background-color: #ffefff;
  color: #b443c6;
}

.travelSubmenuActive {
  width: 13.5rem;
  transition: all 0.1s ease-in-out;
}
.reportskpiSubmenuActive {
  width: 13.5rem;
  transition: all 0.1s ease-in-out;
}

.tax {
  color: var(--purple);
}

.taxTileActive > .mainMenuVerticalBar {
  background-color: var(--purple);
}

.taxActive {
  width: 240px;
  transition: all 0.2s ease-in-out;
}

.taxSubtile:hover {
  color: var(--purple);
}

.taxTileActive {
  background-color: var(--purple-hover);
  color: var(--purple);
}

.taxSubmenuActive {
  width: 13.5rem;
  transition: all 0.1s ease-in-out;
}

.sidebarBackground {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}

.masterButtonContainer {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.masterButton {
  display: flex;
  align-items: center;
  width: 54%;
  box-shadow: 0px 1.89445px 14.2084px rgb(0 0 0 / 6%);
  border-radius: 4px;
  padding: 6px;
  color: white !important;
  gap: 12px;
  height: fit-content;
}

.masterIconContainer {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  box-shadow: 0px 1.89445px 14.2084px rgb(0 0 0 / 6%);
  border-radius: 4px;
}

.masterIconContainer > img {
  height: 30px;
  width: 30px;
}

.masterButton > label {
  line-height: 15px;
  letter-spacing: 0.02em;
}

