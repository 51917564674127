.teamContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    font-size: 14px;
}

.teamContentHolder {
    width: 100%;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.teamCard {
    margin: 1rem;
    height: 450px;
    width: 300px;
    background: #FFFFFF;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    background-image: url("../../../Assets/TeamCardBackground.svg");
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.subTeamContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    font-size: 14px;
    width: 90%;
}

.teamTileContentHolder {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.badgeHolderTeam {
    width: 90%;
    height: 3rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #D8F0F2;
    box-shadow: 0px 2.78226px 13.9113px rgba(0, 0, 0, 0.06);
    border-radius: 5.56452px;
    margin-top: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.teamBadgeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 9px;
}

.teambadge {
    width: 25.97px;
    height: 25.97px;
    left: 447.27px;
    top: 661.1px;
    background: #D3D2F8;
    border-radius: 16.2298px;
}
.teamMemberName{
    font-weight: 500;
    color: var(--primary);
    margin-top: 1rem;
}
.teamMemberRole{
    margin-top: 0.25rem;
}
.teamMemberLine{
    background-color: var(--primary);
    height: 3px;
    width: 40%;
    margin-top: 0.5rem;
    border-radius: 10px;
}
.teamMemberDesignation{
    font-weight: 500;
    align-self: center;
}
.teamMemberEmail{
    font-weight: 500;
}
.teamMemberStatus{
    padding: 2px 10px;
    color: var(--primary);
    background-color: var(--primary-hover);
    align-self: flex-start;
    border-radius: 4px;
    font-weight: 500;
}