.candidateProfileContainer {
    width: 83rem;
    box-shadow: 0px 3px 15px rgb(0 0 0 / 10%);
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-image: none;
}

.resumeHolder {
    position: absolute;
    right: 22px;
}

.resumeImage {
    height: 42px;
}

.candidateProfileHeader {
    position: relative;
    width: 100%;
    margin: 1rem;
    align-items: center;
    display: flex;
    height: 16rem;
    flex-shrink: 0;
}

.candidateSpecificDetails {
    margin: 1rem;
    background-image: none;
    width: 31rem;
    /* height: 18rem; */
}

.candidateDetails {
    justify-content: space-around;
}

.candidateHorizontalLine {
    width: 100%;
    flex-shrink: 0;
    background-color: var(--light-grey);
    height: 2px;
}

.candidateIconHolder {
    width: 14rem;
}

.candidateMoreDetails {
    padding-top: 20px;
}

.candidateSocialIconContainer {
    position: absolute;
    right: 40px;
    bottom: -18px;
    margin: 0.5rem;
}

.candidateContainer {
    height: 100%;
}

.candidateProfilePicHolder {
    height: 32rem;
    left: -65px;
}