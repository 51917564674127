.showFullAttachmentCreatePopupBox1 {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  left: 0;
  justify-content: center;
  z-index: 999;
}
.showFullAttachmentCreatePopupBox1 {
  display: flex;
  flex-direction: column;
  contain: content;
  position: relative;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #999;
  border-radius: 6px;
  max-height: 80%;
  min-height: 60%;
  max-width: 80%;
  min-width: 50%;
  margin-top: 30px;
  left: -50px;
}
.showFullAttachmentPopupBox1 {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  left: 0;
  justify-content: center;
  z-index: 999;
}
.showFullAttachmentPopupBox2 {
  display: flex;
  flex-direction: column;
  contain: content;
  position: relative;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #999;
  border-radius: 6px;
  max-height: 80%;
  min-height: 80%;
  max-width: 45%;
  min-width: 45%;
  margin-top: 3%;
}
.showFullAttachmentPopupBox2 .horizontalLine {
  display: none;
}
.showFullAttachmentPopupBox2 .popupContentHolder {
  display: flex;
  flex-direction: unset;
  flex-grow: unset;
  justify-content: unset;
}
.showFullAttachmentPopupBox2 .createLine {
  display: none;
}
.showFullAttachmentPopupBox2 .showFullAttachmentWrapper {
  display: flex;
  flex: 1;
}
.showFullAttachmentWrapper .showFullAttachmentListBox {
  display: flex;
  flex: 1;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
}
.showFullAttachmentWrapper .showFullAttachmentList {
  height: 550px;
}

.showAttachmentsWithCommentPopupbox2 {
  min-width: 70%;
  max-width: 70%;
  min-height: 500px;
  max-height: 85%;
  contain: none;
  background: #fff;
  border: none;
  overflow: hidden;
  top: 3%;
}
.showAttachmentsWithCommentPopupbox2 .createLine{
height: 0 !important;
}
.showAttachmentsWithCommentPopupbox1 .bodyContainer {
  overflow: hidden;
}
.showAttachmentsWithCommentPopupbox1 .popupContentHolder {
  padding: 0;
  font-size: 14px;
  display: flex;
  flex-direction: unset;
  flex-grow: unset;
  justify-content: unset;
  border: none;
  background-color: #fff;
}
.showAttachmentsWithCommentCreateHead {
  display: none !important;
}
.showAttachmentsWithCommentWrapper {
  width: 50%;
  position: relative;
  display: flex;
}
.showAttachmentsWithCommentImgContainer img {
  width: 100%;
  height: 540px;
  object-fit: contain;
  padding: 12px;
}
.showAttachmentsWithCommentImgContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
}
.showAttachmentsWithCommentListContainer {
  width: 50%;
  background: #fff;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.06);
}
.showAttachmentsWithCommentListWrapper
  .showAttachmentsWithCommentListHeaderContainer {
  border-radius: 0px 8px 0px 0px;
  background: #fff;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.06);
  padding: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.showAttachmentsWithCommentListHeaderContainer div {
  display: flex;
}
.showAttachmentsWithCommentListHeaderContainer div article {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.showAttachmentsWithCommentListHeaderContainer div article label:nth-child(1) {
  color: #1296b0;
  font-size: 15px;
  font-weight: 500;
}
.showAttachmentsWithCommentListHeaderContainer div article label:nth-child(2) {
  color: #bfbfbf;
  font-size: 13px;
  line-height: 10px;
}
.showAttachmentsWithCommentListHeaderContainer div article label:nth-child(3) {
  color: #bfbfbf;
  font-size: 13px;
}

.showAttachmentsWithCommentListBodyContainer {
  overflow: auto;
  height: 500px;
  padding-bottom: 20px;
}
.showAttachmentsWithCommentListBodyWrapper {
  padding: 20px;
}
.showFullGalleryImages1 .createHead {
  display: none;
}
.showFullGalleryImages2 {
  min-height: 540px;
}
.showFullGalleryImages2 .popupContentHolder {
  padding: 20px 12px;
}
.galleryImageContainer-allImages {
  object-fit: contain;
  max-height: 350px;
  height: auto;
  width: auto;
  max-width: 640px;
}
.showAttachmentsWithCommentPopupbox2 .popupContentHolder {
  width: 100%;
}
.showFullAttachmentListBox .react-pdf__Page__canvas {
  height: 550px !important;
  width: fit-content !important;
}
.showFullAttachmentPopupBox2Body {
  flex-direction: unset;
  justify-content: center;
}
.socialPopupBody2:hover .leftPdfArrow {
  opacity: 1 !important;
  visibility: visible !important;
}
.socialPopupBody2:hover .rightPdfArrow {
  opacity: 1 !important;
  visibility: visible !important;
}
.galleryFullAttachmentPopupBox1 {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  left: 0;
  justify-content: center;
  z-index: 999;
}
.galleryFullAttachmentPopupBox2 {
  display: flex;
  flex-direction: column;
  contain: content;
  position: relative;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #999;
  border-radius: 6px;
  max-height: 80%;
  min-height: 50%;
  max-width: 45%;
  min-width: 45%;
  margin-top: 3%;
}
.galleryFullAttachmentPopupBox2 .horizontalLine {
  display: none;
}
.galleryFullAttachmentPopupBox2 .popupContentHolder {
  display: flex;
  flex-direction: unset;
  flex-grow: unset;
  justify-content: unset;
  background-color: black;
  height: 100%;
  width: 100%;
}

.galleryFullAttachmentPopupBox2 .createLine {
  display: none;
}
.galleryFullAttachmentPopupBox2 .showFullAttachmentWrapper {
  display: flex;
  flex: 1;
}
.showAttachmentsWithCommentWrapper .leftPdfArrow {
  visibility: hidden;
}
.showAttachmentsWithCommentWrapper .rightPdfArrow {
  visibility: hidden;
}
.showAttachmentsWithCommentWrapper:hover .leftPdfArrow {
  visibility: visible;
}
.showAttachmentsWithCommentWrapper:hover .rightPdfArrow {
  visibility: visible;
}
.social-details-header {
  padding: 10px;
}
